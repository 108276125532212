import React, { useState, useEffect, useRef } from "react";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
    Chart,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Filler,
    Title,
    Tooltip,
} from 'chart.js';
import { Stack, Button, Modal, CircularProgress, Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useGetUserDetailsMutation } from '../../redux/api/authApi';
import { usePublishUnpublishCountMutation, useCampaignListMutation } from '../../redux/api/promptApi';
import { usePlanDetailsMutation, useLazyGetProductQuery } from '../../redux/api/productApi';
import { useAppSelector } from "../../redux/store";
import { IPlanDetail } from '../../redux/api/types';
import { getCookie } from "utils/storageUtils";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { toast } from "react-toastify";

const Billing = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { FromCampaign } = location.state || {};
    const { userEmail } = location.state || {};
    const { FromUpdatedCampaign } = location.state || {};

    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);

    const [userDets, setUserDets] = useState<any>();
    const [publishedUnpublishedCount, setPublishedUnpublishedCount] = useState<any>();
    const [planDetails, setPlanDetails] = useState<any>();
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignListDatas, setCampaignListDatas] = useState<any>();
    const [billingDatas, setBillingDatas] = useState<any>();

    const [getUserDetailsMutation] = useGetUserDetailsMutation();
    const [publishUnpublishCountMutation] = usePublishUnpublishCountMutation();
    const [getuserPlanDetails] = usePlanDetailsMutation();
    const genieSelector = useAppSelector((state) => state.genieState);
    const [getProduct, getProductState] = useLazyGetProductQuery();
    const [campaignListMutation] = useCampaignListMutation();
    const [openActivateConfirmationModal, setOpenActivateConfirmationModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const isSubmitting = useRef(false);

    const useremail = localStorage.getItem('user_email') || ''/* ? localStorage.getItem('user_email') : userEmail ? userEmail : getCookie('user_email') */;

    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: useremail || getCookie('user_email') || '' });
            if ('data' in response) {
                setUserDets(response.data.data);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchingPublishDatas = async (campaignId: string) => {
        try {
            const responseCount = await publishUnpublishCountMutation({ email: useremail || getCookie('user_email') || '', campaignid: campaignId });

            if ('data' in responseCount) {
                const datas = responseCount.data;
                setPublishedUnpublishedCount(datas);
            }
        } catch (error) {
            console.log('Error fetching publishing counts data:', error);
        }
    }

    useEffect(() => {
        fetchingPublishDatas('');
    }, []);

    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: useremail, campaignid: '' });

            if ('data' in response) {
                setPlanDetails(response.data.data);
            }
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        fetchPlanDatas();
    }, []);

    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
            save: ''
        },
    ]);

    useEffect(() => {

        const { data } = getProductState;
        if (data?.plan_details) {
            //console.log(data?.plan_details);
            setPlans([...data?.plan_details]);
        }
        else
            setPlans([
                {
                    user: 1,
                    plan_name: "",
                    total_wishes: 0,
                    price: "",
                    period: "",
                    product_id: "",
                    price_id: "",
                    subscription_level: "",
                    feature: [],
                    contracts: 0,
                    type: "",
                    button_text: "",
                    save: ''
                },
            ]);
    }, [getProductState]);

    const campaignRes = async () => {
        try {
            const response = await campaignListMutation({ email: useremail || '' });
            if ('data' in response) {
                setCampaignListDatas(response.data);
                console.log('campaign list response=>', response.data)
            } else {
                //console.error('Error fetching campaign List:', response.error);
            }
        } catch (error) {
            //console.log('Error fetching campaign List:', error);
        }
    }

    useEffect(() => {
        const publishingblogCampId = localStorage.getItem('publishingCampId');
        if (publishingblogCampId) {
            setSelectedCampaign(publishingblogCampId);
            fetchingPublishDatas(publishingblogCampId);
        }
        else if (FromCampaign) {

            setSelectedCampaign(FromCampaign);
            fetchingPublishDatas(FromCampaign);
            localStorage.setItem('campId', FromCampaign);

            const count = 0;
            localStorage.setItem('refreshCount', count.toString());

            const refreshPage = () => {
                const refreshCountStr = localStorage.getItem('refreshCount');
                const refreshCountt = refreshCountStr !== null ? parseInt(refreshCountStr) : 0;

                if (refreshCountt < 1) {
                    setTimeout(() => {
                        localStorage.setItem('refreshCount', (refreshCountt + 1).toString());
                        // window.location.reload();
                        setSelectedCampaign(FromCampaign);
                        fetchingPublishDatas(FromCampaign);

                        // processBlogs();

                    }, 20000);
                }
            };
            refreshPage();

            // **Set FromCampaign to null**
            navigate(location.pathname, {
                replace: true,
                state: { ...location.state, FromCampaign: null },
            });

        } else if (FromUpdatedCampaign) {
            setSelectedCampaign(FromUpdatedCampaign);
            fetchingPublishDatas(FromUpdatedCampaign);
            localStorage.setItem('campId', FromUpdatedCampaign);
        } else if (campaignListDatas && campaignListDatas.length > 0 /* && !selectedCampaign */) {
            const savedCampId = localStorage.getItem('campId');
            if (!savedCampId) {
                // Only set to the 0th index if no other campaign was previously selected
                setSelectedCampaign(campaignListDatas[0].campaign_id);
                fetchingPublishDatas(campaignListDatas[0].campaign_id);
                localStorage.setItem('campId', campaignListDatas[0].campaign_id);
            } else {
                setSelectedCampaign(savedCampId);
                fetchingPublishDatas(savedCampId);
            }
            /* setSelectedCampaign(campaignListDatas[0].campaign_id);
            fetchingPublishDatas(campaignListDatas[0].campaign_id);
            localStorage.setItem('campId', campaignListDatas[0].campaign_id); */

        }
    }, [campaignListDatas, FromCampaign, FromUpdatedCampaign, location, navigate]);

    useEffect(() => {
        campaignRes();
    }, []);

    const BillingLists = async () => {
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(`${apiUrl}/api/payment/get_payment_list/?email=${useremail}`, null,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setBillingDatas(response.data.payments);
            console.log('billing datas=>', response.data.payments);
        } catch (error) {
            console.log('Error fetching billing details', error);
        }
    }

    useEffect(() => {
        BillingLists();
    }, []);

    const handleActivateSubscription = async () => {
        if (isSubmitting.current) return;
        isSubmitting.current = true;

        setIsLoading(true);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';

            const response = await fetch(apiUrl + `/api/subscription/activate_now/?email=${userEmail || useremail}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userEmail }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Subscription activated:', data);
            toast.success(data.message);
            navigate('/activation-success');
        } catch (error) {
            toast.error('Error activating subscription');
            console.error('Error activating subscription:', error);
        } finally {
            isSubmitting.current = false;
            setIsLoading(false);
            // setOpenActivationModal(true);
        }
    }

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, []);


    return (
        <>
            <p style={{ fontWeight: 'bold', fontSize: '32px', color: '#fff' }}>Payment Details</p>
            <p style={{ fontWeight: 'lighter', fontSize: '14px', color: '#969992' }}>Here you can find detail payment history</p>
            <div className="row">
                <div className="col-12">
                    <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff' }}>Blog Summary</p>
                </div>
            </div>

            <div className="row" style={{ display: 'flex', gap: '20px' }}>
                <div className="col-sm-12 col-md-4 col-lg-4" style={{ background: '#15151D', borderRadius: '8px', width: isMobile ? '100%' : '38%' }}>
                    <div style={{ margin: '3%' }}>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th className="card_headings" colSpan={2}>
                                        Blog Remaining
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="card_val" style={{ width: '35%', position: 'relative', textAlign: 'left' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column'/* , alignItems: 'center' */ }}>
                                            {userDets?.blog_count_balance}
                                            <span className="card_subheadings">Text/Website</span>
                                        </div>
                                        <div style={{
                                            position: 'absolute',
                                            top: '21%',
                                            right: 0,
                                            height: '74%',
                                            width: '1px',
                                            backgroundColor: '#545253',
                                        }}></div>
                                    </td>
                                    <td className="card_val" style={{ width: '65%', textAlign: 'left', paddingLeft: '8%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column'/* , alignItems: 'center' */ }}>
                                            {userDets?.podcast_count_balance}
                                            <span className="card_subheadings">YouTube/Video/Audio</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3" style={{ background: '#15151D', borderRadius: '8px', width: isMobile ? '100%' : '30%' }}>
                    <div style={{ margin: '3%' }}>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th className="card_headings" colSpan={2}>
                                        Blog Counts
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="card_val" style={{ width: '100%', position: 'relative', textAlign: 'left' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column'/* , alignItems: 'center' */ }}>
                                            {publishedUnpublishedCount?.total}
                                            <span className="card_subheadings">Total</span>
                                        </div>
                                        {/*  <div style={{
                                                position: 'absolute',
                                                top: '21%',
                                                right: '38px',
                                                height: '74%',
                                                width: '1px',
                                                backgroundColor: '#545253',
                                            }}></div> */}
                                    </td>
                                    {/*  <td className="card_val" style={{ width: '30%', position: 'relative', textAlign: 'left' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {publishedUnpublishedCount?.published}
                                                <span className="card_subheadings">Published</span>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                top: '21%',
                                                right: '15px',
                                                height: '74%',
                                                width: '1px',
                                                backgroundColor: '#545253',
                                            }}></div>
                                        </td>
                                        <td className="card_val" style={{ width: '40%', position: 'relative', textAlign: 'left' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {publishedUnpublishedCount?.unpubhished}
                                                <span className="card_subheadings">Unpublished</span>
                                            </div>
                                        </td> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5" style={{ background: '#15151D', borderRadius: '8px', width: isMobile ? '100%' : '28%' }}>
                    <div style={{ margin: '3%' }}>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th className="card_headings" /* colSpan={2} */>
                                        Current Plan
                                    </th>
                                    <th className="card_headings" /* colSpan={2} */>
                                        Expiry
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%', position: 'relative', textAlign: 'left', borderBottom: '1px solid #545253' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: isMobile ? '4%' : '8%', fontWeight: 'bold', color: '#fff', fontSize: '16px' }}>
                                            {planDetails?.plan_name ? planDetails?.plan_name : 'Non-Subscribed'}
                                            {userDets?.payment_status === 'Free Trial' && <br />}
                                            {userDets?.payment_status === 'Free Trial' && `(${userDets?.payment_status})`}
                                        </div>
                                    </td>
                                    <td style={{ width: '50%', position: 'relative', textAlign: 'left', borderBottom: '1px solid #545253' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: isMobile ? '4%' : '8%', fontWeight: 'bold', color: '#fff', fontSize: '16px' }}>
                                            {planDetails?.expiry_date ? new Date(planDetails?.expiry_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            }) : '-'}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%', position: 'relative', textAlign: 'left' }}>
                                        <div style={{ display: 'flex', fontWeight: 'lighter', color: '#fff', fontSize: '14px', gap: '8px', marginTop: '2%' }}>
                                            {(parseInt(localStorage.getItem('free_plan') || '0') === 0 || parseInt(localStorage.getItem('free_plan') || '1') || (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5")) && userDets?.payment_status !== 'Free Trial' ? (
                                                <button className="button_leniar" style={{ borderRadius: '30px', height: '27px', width: '100%', fontSize: '14px', fontWeight: 'lighter' }}
                                                    onClick={() => {
                                                        const value = {
                                                            _id: "",
                                                            product_name: "CodeGenie",
                                                            product_module: genieSelector.module ? genieSelector.module : "",
                                                            userid: "",
                                                            productid: ""
                                                        };
                                                        getProduct(value);
                                                        localStorage.setItem('fromUpgrade', 'true');
                                                        navigate('/pricing', { state: { campIdFromUpgrade: selectedCampaign, from_page: 'blog_listing' } });
                                                    }}>Upgrade</button>
                                            ) : (<Button
                                                sx={{
                                                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                    marginLeft: 0,
                                                    color: "white",
                                                    paddingX: 2,
                                                    paddingY: 1,
                                                    marginRight: 1,
                                                    borderRadius: '30px', height: '27px', width: '100%', fontSize: '14px', fontWeight: 'lighter'
                                                }}
                                                onClick={() => setOpenActivateConfirmationModal(true)}
                                            >
                                                Activate
                                            </Button>)}
                                        </div>
                                    </td>
                                    <td style={{ width: '50%', position: 'relative', textAlign: 'left' }}>
                                        <button style={{ borderRadius: '30px', height: '27px', width: '100%', fontSize: '14px', fontWeight: 'lighter', color: '#CACBCC', border: '1px solid #545253', background: 'none' }} onClick={() => window.open('/contactus')}>Help</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: '2%' }}>
                <div className="col-12">
                    <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff' }}>Payment List</p>
                </div>
            </div>

            <div className="row" /* style={{ marginTop: '1%' }} */>
                <div className="col-12" style={{ background: '#15151D', borderRadius: '8px', width: /* isMobile ?  */'100%' /* : '65%' */, overflowX: isMobile ? 'auto' : 'hidden' }}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #242424' }}>
                                <th style={{ padding: '22px 0', width: '30%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                    Invoice No
                                </th>
                                <th style={{ padding: '22px 0', width: '20%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                    Payment Status
                                </th>
                                <th style={{ padding: '22px 0', width: '20%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                    Subscription Date
                                </th>
                                {/* <th style={{ padding: '22px 0', width: '20%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                    Expiry Date
                                </th> */}
                                <th style={{ padding: '22px 0', width: '20%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                    Subscription Plan
                                </th>
                                <th style={{ padding: '22px 0', width: '10%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {billingDatas && billingDatas.length > 0 ?
                                (billingDatas && billingDatas.map((data: any, index: any) => (
                                    <tr style={{ borderBottom: '1px solid #242424', height: '50px' }}>
                                        <td style={{ padding: '10px 0', width: '30%', textAlign: 'left', color: '#4BA5EB', fontWeight: 'lighter', fontSize: '16px' }}>
                                            {data.invoice_no}
                                        </td>
                                        <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                            {data.payment_status}
                                        </td>
                                        <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                            {new Date(data.subscription_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            })}
                                        </td>{/* 
                                    <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                        {new Date(data.expiry_date).toLocaleDateString()}
                                    </td> */}
                                        <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                            {(data.subscription_level === 'level_0' || data.subscription_level === 'level_4') ? 'STARTER' : (data.subscription_level === 'level_1' || data.subscription_level === 'level_5') ? 'CREATOR'
                                                : (data.subscription_level === 'level_2' || data.subscription_level === 'level_6') ? 'PROFESSIONAL' : (data.subscription_level === 'level_3' || data.subscription_level === 'level_7') ? 'ENTERPRISE'
                                                    : (data.subscription_level === 'level_0a') ? 'Life Time - STARTER' : (data.subscription_level === 'level_1a') ? 'Life Time - CREATOR' : (data.subscription_level === 'level_2a') ? 'Life Time - PROFESSIONAL'
                                                        : (data.subscription_level === 'level_3a') ? 'Life Time - ENTERPRISE' : 'No Plan Subscribed'}
                                        </td>
                                        <td style={{ padding: '10px 0', width: '10%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                            {'$' + Number(data.amount).toFixed(2)}
                                        </td>
                                    </tr>)))
                                :
                                <tr>
                                    <td colSpan={5}>
                                        <div className="alert alert-info" role="alert" style={{ textAlign: 'center' }}>
                                            No Records Found
                                        </div>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </div>



            <Modal
                open={openActivateConfirmationModal}
                onClose={() => setOpenActivateConfirmationModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : 600,
                        bgcolor: "background.paper",
                        // border: "2px solid #000",
                        borderRadius: '5px',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    {/* <TaskAltIcon style={{ color: 'green', fontSize: '40px' }} /> */}
                    <p id="child-modal-description" style={{ textAlign: 'center' }}>Activate Plan will immediately start your paid plan and bill your credit card.<br></br>CONTINUE ?</p>
                    <Stack flexDirection="row">
                        {/* <Box sx={{ display: "inline-block", margin: "0 10px" }}>
                                      <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
                                    </Box> */}

                        <Button
                            onClick={handleActivateSubscription}
                            variant="contained"
                            sx={{ mr: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : "Yes"}
                        </Button>

                        <Button
                            onClick={() => {
                                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                                setOpenActivateConfirmationModal(false);
                            }}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            No
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default Billing;