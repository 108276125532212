import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import './ocesha.css';
import { useLocation } from "react-router-dom";
import sample_setting_img from '../../assets/img/card-img-3.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isMobile } from "react-device-detect";
import oceshaLogo from '../../assets/ocesha_images/ocesha_logo.png';
import { object, string, TypeOf, number } from "zod";
import { IgeneratedbusinessblogDet } from '../../redux/api/types';
import {
    TextField,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    InputAdornment,
    IconButton,
    Typography,
    Box,
    Stack,
    Modal,
    Divider,
    SvgIcon,
    Autocomplete,
    Switch,
    CircularProgress,
    TextareaAutosize,
    Tooltip
} from "@mui/material";
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { LoadingButton } from "@mui/lab";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone, AlignHorizontalCenter, Widgets } from "@mui/icons-material";
import { makeStyles } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import demoImage from "../../assets/image1.png";
import { Edit } from "@mui/icons-material";
import { Download } from "@mui/icons-material";
import { Share } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { ThumbUp } from "@mui/icons-material";
import { ThumbDown } from "@mui/icons-material";
import { ReactComponent as EyeIcon } from "../../assets/ico_eye.svg";
import { Link, Navigate } from "react-router-dom";
import { useDeviceData, isBrowser } from "react-device-detect";
import { useGeneratedbusinessurlblogsMutation, useGetBlogDetQuery, useDeleteBlogMutation, useBlogDetailsPublishMutation, useSavingThumbsUpMutation, useSavingThumbsDownMutation, useBlogDetailsDraftMutation, useRegenerateBusinessBlogMutation, useBlogsCountQuery, useCampaignBlogsCountQuery, useCopyurlMutation, usePublishtogoogleMutation, usePublishUnpublishCountMutation, useGetBlogsByCampaignstatusQuery } from "../../redux/api/promptApi";
import { IGetBlogsAdmin } from "../../redux/api/types";
import { useLazyGetProductQuery } from '../../redux/api/productApi';
import { useNavigate } from "react-router-dom";
import PayNowButton from '../../pages/payment/payNowButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as DownloadIcon } from "../../assets/download_ico.svg";
import { ReactComponent as ShareIcon } from '../../assets/share_ico.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash_ico.svg';
import { ReactComponent as EditIcon } from '../../assets/pencil_ico.svg';
import { ReactComponent as LikeIcon } from '../../assets/like_ico.svg';
import { ReactComponent as DisLikeIcon } from '../../assets/dislike_ico.svg';
import { ReactComponent as ViewIcon } from '../../assets/view1_ico.svg';
import { ReactComponent as RegenerateIcon } from '../../assets/regenerate_ico.svg';
import { useGetgeneratedBlogListingsQuery } from '../../redux/api/promptApi';
import { useGetproductsDetQuery } from '../../redux/api/promptApi';
import { ReactComponent as MenuIcon } from '../../assets/Menu_ico.svg';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useGetUserDetailsMutation } from "../../redux/api/authApi";
import { renderToStaticMarkup } from 'react-dom/server';
import { ReactComponent as FeedBackIcon } from '../../assets/ico_feedback.svg'
import { useFeedBackMutation } from '../../redux/api/authApi';
import { store, useAppDispatch, useAppSelector } from "../../redux/store";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { IPlanDetail } from "../../redux/api/types";
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import { useCampaignListMutation, useGetCnameEntriesMutation, useGetBlogsByCampaignQuery, useGetBlogGenerationStatusMutation } from "../../redux/api/promptApi";
import { usePlanDetailsMutation } from "../../redux/api/productApi";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Pagination from "@mui/material/Pagination";
import youtubeIco from '../../assets/traffic_monster/youtubeIco.png';
import youtubeIco1 from '../../assets/traffic_monster/youtubeIco_1.png'
import unsplashImg from '../../assets/traffic_monster/unsplash_ico_logo.png';
// import Stack from "@mui/material";
import unsplash_logo from '../../assets/traffic_monster/unsplash_logo.png';
import CloseIcon from '@mui/icons-material/Close';
import DraftBtn from '../../assets/traffic_monster/draftBtn.png';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import { ReactComponent as ExportIcon } from '../../assets/podcast_monster/export_ico.svg';
import CodeIcon from '@mui/icons-material/Code';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ClipboardIcon from '@mui/icons-material/FileCopy';
import { ReactComponent as WordIcon } from '../../assets/podcast_monster/docx_ico.svg';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ReactComponent as ToggleIcon } from '../../assets/podcast_monster/toggle.svg';
import { ReactComponent as WPLogoWhite } from '../../assets/podcast_monster/wordpressLogo_white.svg';
import { ReactComponent as MediumLogo } from '../../assets/podcast_monster/mediumLogo.svg';
import { ReactComponent as PlayBtn } from '../../assets/podcast_monster/play_btn.svg';
import { ReactComponent as WPLogoBlue } from '../../assets/podcast_monster/wordpressLogo_blue.svg';
import bloggerLogo from '../../assets/podcast_monster/blogger_logo.png';
import ghostLogo from '../../assets/podcast_monster/ghost_logo.png';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { debug } from "console";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { ReactComponent as SaveIcon } from '../../assets/podcast_monster/save_ico.svg';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MicIcon from '@mui/icons-material/Mic';
import transcript_img from '../../assets/podcast_monster/transcript_img.png';
import SettingsIcon from '@mui/icons-material/Settings';
import { getCookie, removeCookie } from "utils/storageUtils";
import best_1 from '../../assets/ocesha_images/best_1.png';
import { ReactComponent as NotePencilIco } from '../../assets/ocesha_images/note_pencil_ico.svg';
import { ReactComponent as DeletIcon } from '../../assets/ocesha_images/delete_ico.svg';
import { ReactComponent as SubMenuIcon } from '../../assets/ocesha_images/sub_menu_ico.svg';
import { ReactComponent as TrashIco } from '../../assets/ocesha_images/trash_ico.svg';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ReactComponent as EditIco } from '../../assets/ocesha_images/edit_ico.svg';
import { ReactComponent as ViewIco } from '../../assets/ocesha_images/view_ico.svg';
import { ReactComponent as ExportIco } from '../../assets/ocesha_images/export_ico.svg';
import { ReactComponent as DeleteIco } from '../../assets/ocesha_images/delete_icon.svg';
import { ReactComponent as RegenerateIco } from '../../assets/ocesha_images/regenerate_ico.svg';
import Ocesha_header from "./ocesha_header";
import { logout } from "redux/features/userSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { ReactComponent as LinkIcon } from '../../assets/ocesha_images/link_ico.svg';
import ComputerIcon from '@mui/icons-material/Computer';

interface Blog {
    id: string;
    image_updated: 'Yes' | 'No';
    // Add other properties as needed
}

const customInputStyle = {
    color: '#000',
    border: '1px solid #CBCBCB',
};

let timestampInMilliseconds = Date.now();
let milliSeconds = Math.floor(timestampInMilliseconds / 1000);


const feedbackform = object({
    email: string(),
    rating: number(),
    feedback: string(),
    productname: string(),
    date: string()
});

const blogUpdate = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    publish_date: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_label: string(),
    cta_url: string(),
    blog_image_path: string(),
    videotag: string(),
    product_id: string()
})

export type FeedBackForm = TypeOf<typeof feedbackform>

export type BlogUpdate = TypeOf<typeof blogUpdate>

const blogDraft = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_label: string(),
    cta_url: string()
})

export type BlogDraft = TypeOf<typeof blogDraft>

interface LoadingStates {
    [key: string]: boolean;
}

//test comment to fource merge
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#1377C3', 
        backgroundColor: '#0F74BF',
        color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: 'white',
        /* backgroundColor: '#131825', */ // Set background color for odd rows to white
        color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
        // backgroundColor: '#F2F6FA',
        /* backgroundColor: '#131825', */ // Set background color for even rows to gray
        color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface UnsplashImage {
    id: string;
    urls: {
        thumb: string;
        regular: string;
    };
    alt_description: string;
    width: number;
    height: number;
}

const EditBlog = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { FromCampaign } = location.state || {};
    const { FromUpdatedCampaign } = location.state || {};
    const { FromDashboard, ...otherState } = location.state || {};
    const { userEmail } = location.state || {};
    // const { blog } = location.state || {};
    const blog = location.state;

    console.log('blog detailsss=>', blog);
    const [selected, setSelected] = useState(false);
    const [openEdit, setOPenEdit] = useState(false);
    const [openPaidModal, setOpenPaidModal] = useState(false);
    const [helperModal, setHelperModal] = useState(FromDashboard === 'true' ? true : localStorage.getItem('AlertMessage') === 'false' ? false : false);
    const [isLiked, setIsLiked] = useState('');
    const [isDisLiked, setIsDisLiked] = useState('');

    //feedback popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const ratings = [1, 2, 3, 4, 5];
    const user = useAppSelector((state) => state.userState.user);
    const [productName, setProductName] = useState<string | null>(localStorage.getItem('module'));
    const currentDate = new Date().toISOString();
    const [feedbackForm, setFeedbackForm] = useState<FeedBackForm>({ email: localStorage.getItem('user_email') || "", rating: 0, feedback: '', productname: productName || '', date: currentDate })
    const [feedbackMutation] = useFeedBackMutation();

    const [getProduct, getProductState] = useLazyGetProductQuery();
    const genieSelector = useAppSelector((state) => state.genieState);
    const [wishRemCount, setWishRemCount] = useState(0);
    const [isTranscribeEditable, setIsTranscribeEditable] = useState<boolean>(false);


    const [generatedBlogDetail, setGeneratedBlogDetail] = useState<any>();
    const [productDetail, setProductDetail] = useState<any>();
    const [productURL, setProductURL] = useState('');
    const [planDetails, setPlanDetails] = useState<any>();
    const [timestampInSeconds, setMilliSeconds] = useState(0);
    const [freePlann, setFreePlan] = useState(localStorage.getItem('free_plan'));
    const [subscriptionLevel, setSubscriptionLevel] = useState(localStorage.getItem('subscription_level'));
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [choosingImageModal, setChoosingImageModal] = useState<boolean>(false);

    const staticTypeOptions = ['Blogs']
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, false] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        ],
    };

    const [generatedBusinessUrlBlogsMutation] = useGeneratedbusinessurlblogsMutation();
    const [regenerateBusinessBlogMutation] = useRegenerateBusinessBlogMutation();
    const [publishUnpublishCountMutation] = usePublishUnpublishCountMutation();

    const [publishedUnpublishedCount, setPublishedUnpublishedCount] = useState<any>();
    const [userDets, setUserDets] = useState<any>();
    const [getUserDetailsMutation] = useGetUserDetailsMutation();

    const user_email = localStorage.getItem('user_email') ? localStorage.getItem('user_email') : userEmail ? userEmail : getCookie('user_email') ? getCookie('user_email') : '';

    // const blogGenerated = localStorage.getItem('Blogs Generated');

    const [selectedBlogIndex, setSelectedBlogIndex] = useState(0);

    const [isLoading, setIsLoading] = useState<LoadingStates>({});

    const [isLoadingCopied, setIsLoadingCopied] = useState<LoadingStates>({});

    const [selectedBlogTitle, setSelectedBlogTitle] = useState('');

    const [searchQuery, setSearchQuery] = useState('');

    const [updateblogs, setUpdateBlogs] = useState<BlogUpdate>({ id: blog?.id || '', blog_title: blog?.blog_title || '', blog_image_path: blog?.blog_image_path || '', blog_image_string: blog?.blog_image_string || '', blog_summary: blog?.blog_summary || '', blog_det: blog?.blog_det || '', url: blog?.url || '', publish_date: blog?.publish_date || '', author: blog?.author || '', author_image: blog?.author_image || '', author_url: blog?.author_url || '', cta_label: blog?.cta_label || '', cta_url: blog?.cta_url || '', videotag: blog?.videotag || '', product_id: blog?.product_id || '' });

    const [selectedBlog, setSelectedBlog] = useState<IgeneratedbusinessblogDet | null>();

    const [prevImgLoader, setPrevImgLoader] = useState<boolean>(false);

    const [deleteBlogMutation] = useDeleteBlogMutation();

    const [savingthumbsupMutation] = useSavingThumbsUpMutation();

    const [savingthumbsdownMutation] = useSavingThumbsDownMutation();

    const [blogDetailsDraftMutation] = useBlogDetailsDraftMutation();
    const [blogDetailsPublishMutation] = useBlogDetailsPublishMutation();
    const [copySharingUrlMutation] = useCopyurlMutation();
    const [publishtogoogleMutation] = usePublishtogoogleMutation();
    const [searchResults, setSearchResults] = useState([]);
    const [authUrl, setAuthUrl] = useState('');
    const [blogImg, setBlogImg] = useState('');
    const [authorImg, setAuthorImg] = useState('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [authorImgFile, setAuthorImgFile] = useState<File | null>(null);
    const [isDraftOrPublishLoading, setIsDraftOrPublishLoading] = useState<boolean>(false);
    const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState<any>();
    //const [varImageUpdated, setVarImageUpdated] = useState<boolean>(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [blogIdForExport, setBlogIdForExport] = useState<any>();
    const [urlForExport, setUrlForExport] = useState<any>();
    const [productIdForExport, setproductIdForExport] = useState<any>();
    const [titleForExport, setTitleForExport] = useState<any>();
    const [summaryForExport, setSummaryForExport] = useState<any>();
    const [isDownloadLoading, setIsDownloadLoading] = useState<any>();
    const [isLoadingShareLink, setIsLoadingShareLink] = useState<string | null>(null);
    const [isLoadingEditPost, setIsLoadingEditPost] = useState<string | null>(null);

    let varImageUpdated = false;

    const [getuserPlanDetails] = usePlanDetailsMutation();
    const [openUnsplash, setOpenUnsplash] = useState<any>();
    const [selectedUnsplashImg, setSelectedUnsplashImg] = useState(null);
    const [helpervideosrc, setHelperVideoSrc] = useState<any>();
    const [helperPopup, setHelperPopup] = useState<boolean>(false);
    const [sharingpostdata, setSharingPostData] = useState<any>();
    const [openPostEdit, setOpenPostEdit] = useState<boolean>(false);
    const [upgradeModal, setUpgradeModal] = useState<boolean>(false);
    const [transcriptModal, setTranscriptModal] = useState<boolean>(false);
    const [transcribedData, setTranscribedData] = useState<any>();
    const [activeItem, setActiveItem] = useState(localStorage.getItem('activeItem') || 'dashboard');
    const [isEditing, setIsEditing] = useState(false);
    const [showToolbar, setShowToolbar] = useState(false);
    const [editorRange, setEditorRange] = useState<any>(null);
    const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
    const [toolbarVisible, setToolbarVisible] = useState(false);
    const quillTitleRef = useRef<ReactQuill | null>(null);
    const quillBodyRef = useRef<ReactQuill | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [blogContent, setBlogContent] = useState<any>('');
    const initialContent = useRef(false);


    console.log('blog details from listing=>', blog);

    // const videoRef = useRef<HTMLVideoElement>(null);

    /* const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }; */
    const handlePlayVideo = (videoSrc: any) => {
        // window.open('/images/wordpress.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }
    const handlePlayVideo1 = (videoSrc: any) => {
        // window.open('/images/medium.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }

    const handlePlayVideo2 = (videoSrc: any) => {
        // window.open('/images/blogger.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }

    const handlePlayVideo3 = (videoSrc: any) => {
        // window.open('/images/ghost.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }

    useEffect(() => {
        const loggedIn = localStorage.getItem("logged_in");
        if (loggedIn !== "true") {
            navigate("/signin");
        }
    }, [navigate]);



    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
            save: ''
        },
    ]);

    useEffect(() => {
        if (userEmail) {
            localStorage.setItem('user_email', userEmail || getCookie('user_email'));
        }
    }, [userEmail]);
    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: user_email, campaignid: ''/* selectedCampaign */ });

            if ('data' in response) {
                setPlanDetails(response.data.data);
                // console.log('plan details=>', planDetails.data);
            }
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        if (helperModal) {
            localStorage.setItem('AlertMessage', "true");
        } else if (!FromDashboard) {
            localStorage.setItem('AlertMessage', "false");
        }
        setMilliSeconds(milliSeconds);
    });

    useEffect(() => {
        fetchPlanDatas();
        // console.log(planDetails)
    }, [/* planDetails */]);

    useEffect(() => {
        // Update the name state when userDetails changes
        if (userDets)
            setAuthUrl(userDets.social_url);
    }, [userDets]);

    const handleBlogSelect = (index: any) => {
        setSelectedBlogIndex(index);
    }
    // const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(updateblogs.blog_det)));

    useEffect(() => {
        const fetchData = async () => {
            if (FromDashboard) {
                try {
                    const response = await generatedBusinessUrlBlogsMutation({ userid: user_email, createddate: '', createddate_to: '' });
                    if ('data' in response) {
                        const { data } = response.data;
                        setGeneratedBlogDetail(data);

                        console.log('data from variable=>', data);
                    } else if ('error' in response) {

                        const { error } = response;
                        //console.log('Error:', error);
                    }
                } catch (error) {
                    //console.error('Error fetching blogs:', error);
                }
            }
        };

        fetchData();
    }, [FromDashboard, generatedBusinessUrlBlogsMutation, user_email, timestampInSeconds]);

    const generatingCount = parseInt(localStorage.getItem('Generating Count') || '0') >= 1;
    const freePlan = parseInt(localStorage.getItem('free_plan') || '0') === 0;

    useEffect(() => {
        if (getBlogsCount?.data > 0) {
            localStorage.setItem('blogsCount', 'true');
        } else {
            localStorage.setItem('blogsCount', 'false');
        }
    })

    useEffect(() => {
        if (generatingCount) {
            localStorage.setItem("free_plan", "0");
        }
    })

    const handleRegenerateBlogClick = () => {
        if (generatingCount) {
            localStorage.setItem("free_plan", "0");
            navigate('/codegenie/dashboard');
        }
        /* if (freePlan) {
            setOpenPaidModal(true);
        } */ /* else {
            navigate('/codegenie/dashboard');
        } */
    }

    const handleFormSubmit = () => {
        // Call the feedbackMutation function with the form data as payload
        feedbackMutation(feedbackForm)
            .then((response) => {
                // console.log('Feedback sent successfully:', response);
                toast.success('FeedBack Sent Successfully!!');
                setIsPopupOpen(false);
                setFeedbackForm({
                    email: localStorage.getItem('user_email') || userEmail || '',
                    rating: 0,
                    feedback: '',
                    productname: 'TrafficMonster',
                    date: currentDate
                });

            })
            .catch((error) => {
                console.error('Error sending feedback:', error);

            });
    };

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // Update feedbackForm with the textarea value
        setFeedbackForm((prevForm) => ({
            ...prevForm,
            feedback: event.target.value,
        }));

    };

    const handleRating = (event: number) => {
        // Update feedbackForm with the textarea value
        setFeedbackForm((prevForm) => ({
            ...prevForm,
            rating: event
        }));

    };

    //Edit functionality in Listing
    const handleEditClick = (blog: IgeneratedbusinessblogDet) => {
        //console.log('blog=>', blog);
        setSelectedBlog(blog);
        setOPenEdit(true);

        const mode = blog.mode || 'Live';
        console.log(blog.mode);

        let updatedBlogDet = blog.blog_det_draft ? blog.blog_det_draft : blog.blog_det;

        //do some formatting for blog details
        let htmlstring = "<div>";


        {
            (updatedBlogDet) ? updatedBlogDet.split('\n\n').map((paragraph: any, idx: any) => {

                paragraph = paragraph.replace(/\*\*/g, '');
                paragraph = paragraph.replace(/\#\#\#/g, '');
                paragraph = paragraph.replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '');
                paragraph = paragraph.replace(/\"/g, '');
                paragraph = paragraph.replace(/:/g, ' ');

                if (paragraph.includes('://') || paragraph.includes('//')) {
                    let newBlogDet = (
                        <div key={idx}><div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h5>", "</br><h5>") }} /><br /></div>
                    );
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);

                } else if (!paragraph.match(/<h5>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {

                    let newBlogDet = (
                        <div key={idx}>
                            <br />
                            <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />
                        </div>
                    );
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                } else if (paragraph.includes(':')) {
                    if (paragraph.indexOf(':') < 100) {
                        let parts = paragraph.split(':');
                        let label = parts[0];
                        label = label.replace('<h5>', '');
                        label = label.replace('</h5>', '');
                        label = label.replace('<br>', '');
                        let content = "";
                        if (parts.slice(1).indexOf(":") >= 0) {
                            content = parts.slice(1).join(':');
                        } else
                            content = parts[1];
                        content = content.replace('<h5>', '');
                        content = content.replace('</h5>', '');
                        content = content.replace('<br>', '');
                        let newBlogDet = (
                            <div key={idx}>
                                <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else {
                        let newBlogDet =
                            (
                                <div key={idx}>
                                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                                </div>
                            );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    }
                } else {
                    let modifiedParagraph = "";
                    let newparagraph = paragraph.split('\n');
                    if (newparagraph[0].length < 100) {
                        if (newparagraph[0].indexOf("<h5>") !== -1)
                            modifiedParagraph = newparagraph[0];
                        else
                            modifiedParagraph = "<h5>" + newparagraph[0] + "</h5>";
                        for (var i = 1; i < newparagraph.length; i++) {
                            modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                    } else {
                        for (var i = 0; i < newparagraph.length; i++) {
                            modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                        modifiedParagraph = modifiedParagraph.replace("<h5>", "");
                        modifiedParagraph = modifiedParagraph.replace("</h5>", "");
                        modifiedParagraph = modifiedParagraph.replace("<div", "<div>");
                        modifiedParagraph = modifiedParagraph.replace("</div>", "</div>");
                    }

                    let newBlogDet = (<div key={idx}><div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} /> <br /></div>);
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    return (
                        ""
                    );
                }
            }) :
                blog.blog_det_draft && blog.blog_det_draft.split('\n\n').map((paragraph: any, idx: any) => {

                    paragraph = paragraph.replace(/\*\*/g, '');
                    paragraph = paragraph.replace(/\#\#\#/g, '');
                    paragraph = paragraph.replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '');
                    paragraph = paragraph.replace(/\"/g, '');
                    paragraph = paragraph.replace(/:/g, ' ');

                    if (paragraph.includes('://') || paragraph.includes('//')) {
                        let newBlogDet = (
                            <div key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h5>", "<h5>") }} />
                                <br />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else if (!paragraph.match(/<h5>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {
                        let newBlogDet = (
                            <div key={idx}>
                                <br />
                                <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else if (paragraph.includes(':')) {
                        if (paragraph.indexOf(':') < 100) {
                            let parts = paragraph.split(':');
                            let label = parts[0];
                            label = label.replace('<h5>', '');
                            label = label.replace('</h5>', '');
                            label = label.replace('<br>', '');
                            let content = "";
                            if (parts.slice(1).indexOf(":") >= 0) {
                                content = parts.slice(1).join(':');
                            } else
                                content = parts[1];
                            content = content.replace('<h5>', '');
                            content = content.replace('</h5>', '');
                            content = content.replace('<br>', '');
                            let newBlogDet = (
                                <div key={idx}>
                                    <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />
                                </div>
                            );
                            htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                        } else {
                            let newBlogDet = (
                                <div key={idx}>
                                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                                </div>
                            );
                            htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                        }
                    } else {
                        let modifiedParagraph = "";
                        let newparagraph = paragraph.split('\n');
                        if (newparagraph[0].length < 100) {
                            if (newparagraph[0].indexOf("<h5>") !== -1)
                                modifiedParagraph = newparagraph[0];
                            else
                                modifiedParagraph = "<h5>" + newparagraph[0] + "</h5>";
                            for (var i = 1; i < newparagraph.length; i++) {
                                modifiedParagraph = modifiedParagraph + newparagraph[i];
                            }
                        } else {
                            for (var i = 0; i < newparagraph.length; i++) {
                                modifiedParagraph = modifiedParagraph + newparagraph[i];
                            }
                            modifiedParagraph = modifiedParagraph.replace("<h5>", "");
                            modifiedParagraph = modifiedParagraph.replace("</h5>", "");
                            modifiedParagraph = modifiedParagraph.replace("<div", "<div>");
                            modifiedParagraph = modifiedParagraph.replace("</div>", "</div>");
                        }
                        let newBlogDet = (
                            <div key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} />
                                <br />
                            </div>
                        );

                        return ("");
                    }
                })
        }

        htmlstring = htmlstring + "</div>";

        htmlstring = htmlstring.replace(/<h5>/g, "<h3>");
        htmlstring = htmlstring.replace(/<\/h5>/g, "</h3>");

        htmlstring = updatedBlogDet;

        //formatting ends
        let user_image = userDets?.user_image ? userDets?.user_image : '';
        let social_url = userDets?.social_url ? userDets?.social_url : '';

        /*if (mode === 'Live') {
            setUpdateBlogs({
                id: blog._id,
                blog_title: blog.blog_title,
                blog_image_path: blog.blog_image_path,
                blog_image_string: blog.blog_image_string,
                blog_summary: blog.blog_summary,
                blog_det: htmlstring,
                url: blog.url,
                publish_date: blog.publish_date,
                author: blog.author ? blog.author : userDets.name,
                author_image: blog.author_image ? blog.author_image : user_image,
                author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : social_url,
                cta_label: blog.cta_label,
                cta_url: blog.cta_url

            });
            //console.log(updateblogs);
        }
        else {*/
        if (mode === 'Live') {
            setUpdateBlogs({
                id: blog._id,
                blog_title: /* blog.blog_title_draft ? blog.blog_title_draft :  */blog.blog_title,
                blog_image_path: /* blog.blog_image_path_draft ? blog.blog_image_path_draft : */ blog.blog_image_path,
                blog_image_string: blog.blog_image_string,
                blog_summary: /* blog.blog_summary_draft ? blog.blog_summary_draft : */ blog.blog_summary,
                blog_det: htmlstring,
                url: /* blog.url_draft ? blog.url_draft : */ blog.url,
                publish_date: blog.publish_date,
                author: blog.author ? blog.author : userDets.name,
                author_image: blog.author_image ? blog.author_image : (user_image || ''),
                author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : (planDetails ? planDetails?.social_url : social_url),
                cta_label: blog.cta_label ? blog.cta_label : planDetails?.cta_lable,
                cta_url: blog.cta_url ? blog.cta_url : planDetails?.cta_link,
                videotag: blog.videotag ? blog.videotag : planDetails?.videotag,
                product_id: blog.product_id
            });
        } else {
            setUpdateBlogs({
                id: blog._id,
                blog_title: blog.blog_title_draft ? blog.blog_title_draft : blog.blog_title,
                blog_image_path: blog.blog_image_path_draft /* ? blog.blog_image_path_draft : blog.blog_image_path */,
                blog_image_string: blog.blog_image_string,
                blog_summary: blog.blog_summary_draft /* ? blog.blog_summary_draft : blog.blog_summary */,
                blog_det: htmlstring,
                url: blog.url_draft /* ? blog.url_draft : blog.url */,
                publish_date: blog.publish_date,
                author: blog.author ? blog.author : userDets?.name,
                author_image: blog.author_image ? blog.author_image : (user_image || ''),
                author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : (planDetails ? planDetails?.social_url : social_url),
                cta_label: blog.cta_label ? blog.cta_label : planDetails?.cta_lable,
                cta_url: blog.cta_url ? blog.cta_url : planDetails?.cta_link,
                videotag: blog.videotag ? blog.videotag : planDetails?.videotag,
                product_id: blog.product_id
            });
        }
        /* setUpdateBlogs({
            id: blog._id,
            blog_title: blog.blog_title_draft ? blog.blog_title_draft : blog.blog_title,
            blog_image_path: blog.blog_image_path_draft ? blog.blog_image_path_draft : blog.blog_image_path,
            blog_image_string: blog.blog_image_string,
            blog_summary: blog.blog_summary_draft ? blog.blog_summary_draft : blog.blog_summary,
            blog_det: htmlstring,
            url: blog.url_draft ? blog.url_draft : blog.url,
            publish_date: blog.publish_date,
            author: blog.author ? blog.author : userDets.name,
            author_image: blog.author_image ? blog.author_image : user_image,
            author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : (planDetails ? planDetails?.social_url : social_url),
            cta_label: blog.cta_label ? blog.cta_label : planDetails?.cta_lable,
            cta_url: blog.cta_url ? blog.cta_url : planDetails?.cta_link,
            videotag: blog.videotag ? blog.videotag : planDetails?.videotag,
            product_id: blog.product_id
        }); */
        //console.log(updateblogs);
        //}
    };



    useEffect(() => {
        // If updateblogs are coming from an API or dynamic data
        if (blog) {
            console.log(updateblogs);
            handleEditClick(blog);
        }
    }, []); //

    const handleDelete = async (blog_id: string) => {
        const confirmed = window.confirm('Are you sure, you want to delete this blog?');
        if (confirmed) {
            try {
                const response = await deleteBlogMutation({ blog_id });
                //console.log(response);
                if ('data' in response) {
                    const datas = response.data;
                    if (datas.status === 'success') {
                        toast.success('Blog Deleted successfully');

                        setGeneratedBlogDetail((prevBlogs: any) => {
                            const updatedBlogs = prevBlogs.filter((blog: any) => blog._id !== blog_id);

                            return updatedBlogs.map((blog: any, index: any) => ({
                                ...blog,
                                serialNumber: index + 1,
                            }));
                        });
                    }
                    else {
                        toast.error('Failed to delete blog');
                    }
                } else {
                    toast.error('Failed to delete blog');
                }
            } catch (error) {
                console.error('Error deleting blog', error);
                toast.error('An error occurred while deleting the blog');
            }
        }
    }

    const handleRegeneratingBlogClick = async (blog_id: string) => {
        setIsLoading(prevLoadingStates => ({
            ...prevLoadingStates,
            [blog_id]: true
        }));
        try {
            const response = await regenerateBusinessBlogMutation({ blog_id });
            //console.log('response of regeneration=>', response);
            if ('data' in response) {
                const regeneratedResp = response.data;
                if (regeneratedResp.status === 'success') {
                    fetchingPublishDatas('');
                    refetch();
                    timestampInMilliseconds = Date.now();
                    milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                    setMilliSeconds(milliSeconds);
                    // window.location.reload();
                    toast.success(regeneratedResp.msg);

                }
            }
        } catch (error) {
            console.error('Error deleting blog', error);
        } finally {
            setIsLoading(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: false
            }));
        }
    }

    const handleTranscriptClick = async (blog_id: string) => {
        if (planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y') {
            setUpgradeModal(true);
            return;
        } else {
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.get(
                    `${apiUrl}/api/prompts/getBlogDet?blog_id=${blog_id}&campId=${null}`,
                );
                console.log('transcript res=>', response.data);
                if (response.data.status === 'success') {
                    const data = response.data.data;
                    const TranscriptData = data.map((item: { product_desc: string }) => item.product_desc);
                    setTranscribedData(TranscriptData);
                    setTranscriptModal(true);
                } else {
                    console.log('Error fetching transcription data!');
                }
            } catch (error) {
                console.log('error getting transcript=>', error);
            }
        }
    }

    const handleCopyTranscriptClick = () => {
        navigator.clipboard.writeText(`${transcribedData}`).then(() => {
            toast.success('Transcript Copied to Clipboard');
        })
    }

    console.log(generatedBlogDetail);


    const handleExportClick = (blogId: string, url: string, productId: string, title: string, summary: string) => {
        console.log(url);
        setUrlForExport(url);
        setBlogIdForExport(blogId);
        setproductIdForExport(productId);
        setTitleForExport(title);
        setSummaryForExport(summary);
        setOpenExportModal(true);
    }

    const handleExportShare = async (mode: any) => {
        if (planDetails?.distribution_to_social_media_platform === true) {
            try {
                setIsLoadingShareLink(mode);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/get_html_path?blog_id=${blogIdForExport}&mode=${mode}`,
                    null,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log('sharing link response=>', response.data);
                if (response.data) {
                    debugger;
                    const { url, blog_summary, blog_title, hashtags } = response.data;
                    let shareUrl = '';
                    switch (mode) {
                        case 'fb':
                            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
                            break;
                        case 'tw':
                            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
                            break;
                        case 'in':
                            shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}`;
                            break;
                        default:
                            break;
                    }
                    window.open(shareUrl, '_blank');
                    /* const url = response.data;
                                    let shareUrl = '';
                    switch (mode) {
                        case 'fb':
                            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(titleForExport)} - ${encodeURIComponent(summaryForExport)}`;
                            break;
                        case 'tw':
                            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(titleForExport)} - ${encodeURIComponent(summaryForExport)}` ;
                            break;
                        case 'in':
                            shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(titleForExport)}&summary=${encodeURIComponent(summaryForExport)}`;
                            break;
                        default:
                            break;
                    }
                    window.open(shareUrl, '_blank'); */
                    /*  const clipboardText = `${localStorage.getItem('editedpostblogtitle') ? localStorage.getItem('editedpostblogtitle') : blog_title}\n\n
                     ${localStorage.getItem('editedpostblogsummary') ? localStorage.getItem('editedpostblogsummary') : blog_summary}\n\n
                     ${localStorage.getItem('editedposthashtags') ? localStorage.getItem('editedposthashtags') : hashtags}\n\n
                     ${url}`; */

                    /* navigator.clipboard.writeText(clipboardText).then(() => {
                        toast.success('Post details copied to clipboard!'); */


                    /* setTimeout(() => {
                        window.open(shareUrl, '_blank');
                    }, 1000);
                }) */
                } else {
                    setIsLoadingShareLink(null);
                    toast.error('Something went Wrong. Please try later!')
                }
            } catch (error) {
                console.log(error);
                setIsLoadingShareLink(null);
            } finally {
                setIsLoadingShareLink(null);
                localStorage.removeItem('editedposthashtags');
                localStorage.removeItem('editedpostblogsummary');
                localStorage.removeItem('editedpostblogtitle');
                localStorage.removeItem('editedposturl');
            }
        } else {
            setUpgradeModal(true);
        }

    };

    const handleExportPublicLinkClick = async (mode: any, ifmedium: any) => {
        /*  const url = window.location.origin + `/PreviewBlogs1/${blogIdForExport}?campId=${productIdForExport}`
          navigator.clipboard.writeText(url);
          toast.success('Link copied to clipboard!'); */
        if (ifmedium === 'medium' && planDetails?.distribution_to_blogging_platform === false) {
            setUpgradeModal(true);
        } else {
            try {
                setIsLoadingShareLink(mode);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/get_html_path?blog_id=${blogIdForExport}&mode=${mode}`,
                    null,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log('sharing link response=>', response.data);
                if (response.data) {
                    navigator.clipboard.writeText(response.data.url);
                    toast.success('Link has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                        style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                    });
                } else {
                    setIsLoadingShareLink(null);
                    toast.error('Something went Wrong. Please try later!')
                }
            } catch (error) {
                console.log(error);
                setIsLoadingShareLink(null);
            } finally {
                setIsLoadingShareLink(null);
            }
        }
    }

    /* const handleDownloadFileClick = async (file_type: string) => {
        if (planDetails?.download_content === true || planDetails?.distribution_to_blogging_platform === true) {
            setIsDownloadLoading(file_type);
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/export?doc_type=${file_type}&blog_id=${blogIdForExport}`,
                    null,
                    {
                        responseType: file_type === 'pdf' || file_type === 'docx' ? 'blob' : 'text',
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log('file response=>', response);
                if (file_type === 'pdf' || file_type === 'docx') {

                    // Creating download link from the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `export.${file_type}`);
                    document.body.appendChild(link);
                    link.click();
                    //remove the link after download
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    if (file_type === 'html' || file_type === 'html1' || file_type === 'html2' || file_type === 'html3' || file_type === 'html4' || file_type === 'html5') {
                        //Removing the first and last quotes if present
                        let htmlContent = response.data;
                        if (htmlContent.startsWith('"') && htmlContent.endsWith('"')) {
                            htmlContent = htmlContent.slice(1, -1);
                        }

                        //Removing all \n and \t
                        htmlContent = htmlContent.replace(/\\n/g, '').replace(/\n/g, '').replace(/\\t/g, '').replace(/\t/g, '');

                        //Unescaping double quotes \" to "
                        htmlContent = htmlContent.replace(/\\"/g, '"');
                        //Unescaping double quotes \\s to \s
                        htmlContent = htmlContent.replace(/\\\\s/g, '\\s').replace(/\\\\\./g, '\\.');

                        //copying the result to clipboard
                        await navigator.clipboard.writeText(htmlContent);
                        toast.success('Blog content copied to clipboard.  Please paste to your blog editor.');
                    } else if (file_type === 'txt') {
                        await navigator.clipboard.writeText(response.data);
                        toast.success('Text content copied to clipboard!');
                    }
                }
            } catch (error) {
                console.log('Unexpected Error:', error);
                // toast.error('Unexpected Error:', error);
            } finally {
                setIsDownloadLoading(null);
            }
        } else {
            setUpgradeModal(true);
        }
    } */


    const handleDownloadFileClick = async (file_type: string) => {

        if (planDetails?.download_content === true || planDetails?.distribution_to_blogging_platform === true) {
            setIsDownloadLoading(file_type);
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/export?doc_type=${file_type}&blog_id=${blogIdForExport}`,
                    null,
                    {
                        responseType: file_type === 'pdf' || file_type === 'docx' ? 'blob' : 'text',
                        headers: {
                            'Content-Type': file_type.indexOf('clipboard') > -1 ? 'application/json' : 'multipart/form-data',
                        },


                    }
                );


                console.log('file response=>', response);

                if (file_type === 'pdf' || file_type === 'docx') {
                    // Creating download link from the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `export.${file_type}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else if (file_type === 'txt' || file_type.indexOf('clipboard') > -1) {
                    if (file_type.indexOf('clipboard') > -1) {

                        const parsedContent = JSON.parse(response.data);
                        var cleanedContent = parsedContent.html_content;
                        var cleanedTextContent = parsedContent.text_conent;
                        const cleanedContentWithHtmlFormatting = cleanedContent
                            .replace(/\\n/g, '<br>')
                            .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                            .replace(/\\"/g, '')
                            .replace(/```/g, '')
                            .replace(/<\/html> \"/g, '</html>');

                        // Convert cleaned content to plain text by stripping HTML tags
                        const plainTextContent = cleanedTextContent
                            .replace(/<br>/g, '\n') // Replace <br> with newlines
                            .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces with regular spaces
                            .replace(/##/g, '')
                            .replace(/\*\*/g, '')
                            .replace(/<[^>]*>/g, ''); // Remove any remaining HTML tags


                        // Create clipboard item with both text/html and text/plain
                        const clipboardItem = new ClipboardItem({
                            'text/html': new Blob([cleanedContentWithHtmlFormatting], { type: 'text/html' }),
                            'text/plain': new Blob([plainTextContent], { type: 'text/plain' }), // For Notepad compatibility
                        });

                        await navigator.clipboard.write([clipboardItem]);
                        toast.success('Content has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                            style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                        });
                        /* try {
                             const clipboardItem = new ClipboardItem({
                                 'text/html': new Blob([cleanedContent], { type: 'text/html' }),
                             });
 
                             await navigator.clipboard.write([clipboardItem]);
                             toast.success('Content copied to clipboard with formatting!');
                         } catch (err) {
                             console.error('Failed to copy content: ', err);
                             toast.error('Failed to copy content!');
                         } */
                    } else {
                        await navigator.clipboard.writeText(response.data);
                        toast.success('Text has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                            style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                        });

                    }
                }
                else {
                    toast.success('HTML has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                        style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                    });
                }
            } catch (error) {
                console.log('Unexpected Error:', error);
            } finally {
                setIsDownloadLoading(null);
            }
        } else {
            setUpgradeModal(true);
        }
    };


    const handlemagicIcoClick = async (mode: any) => {
        if (planDetails?.distribution_to_social_media_platform === true) {
            try {
                setIsLoadingEditPost(mode);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/create_hash_tags?blog_id=${blogIdForExport}&social_media=${mode}`,
                    null,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                if (response.data.status === 'Success') {
                    if (response.data.data) {
                        setSharingPostData(response.data.data);
                    } else {
                        setIsLoadingEditPost(null);
                        toast.error('Error fetching data!');
                    }
                } else {
                    setIsLoadingEditPost(null);
                    toast.error('hashtags not created, try again later!');
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoadingEditPost(null);
                setOpenPostEdit(true);
            }
        } else {
            setUpgradeModal(true);
        }
    }


    // const useremail = localStorage.getItem('user_email') ?? '';
    const useremail = localStorage.getItem('user_email') ? localStorage.getItem('user_email') : userEmail ? userEmail : getCookie('user_email');



    const handleViewClick = (blogId: string, productId: string, mode: string, statichtml: boolean, canonical_url: string) => {
        console.log('view click blog id=>', blogId);
        console.log('canoni url=>', canonical_url);
        localStorage.setItem('prevImg', '');
        let url = `/PreviewBlogs1/${blogId}?campId=${productId}`;
        if (mode == "Live" || mode == 'draft' && statichtml == true)
            url = canonical_url;

        url = url + `?campId=${productId}`

        window.open(url, '_blank');
        // window.open(`/PreviewBlogs1/${blogId}`, '_blank');
    }

    const params = new URLSearchParams(window.location.search);
    const promptid = params.get('promptid') ?? "";
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignListDatas, setCampaignListDatas] = useState<any>();
    const [campaignListMutation] = useCampaignListMutation();
    const [blogGenerationStatusMutation] = useGetBlogGenerationStatusMutation();
    const [generationStatus, setGenerationStatus] = useState<Boolean>(true);
    const [metricStatus, setMetricStatus] = useState('');
    const [totalMetric, setTotalMetric] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;
    const domain = window.location.hostname;
    const getBlogsCount = useBlogsCountQuery({ promptid: promptid, userid: useremail || getCookie('user_email') || '', cat: "", query: "", domain: domain });
    const getCampaignBlogsCount = useCampaignBlogsCountQuery({ campaignid: selectedCampaign || '', userid: useremail || getCookie('user_email') || '', cat: "", query: "", domain: domain });
    const getProducts = useGetproductsDetQuery({ user_id: useremail || getCookie('user_email') || '' });
    const getBlogs = useGetgeneratedBlogListingsQuery({ promptid: promptid, userid: user_email || getCookie('user_email') || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize });
    const getCampaignBasedBlogs = useGetBlogsByCampaignQuery({ campaignid: selectedCampaign || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize });
    const getCampaignStatusBasedBlogs = useGetBlogsByCampaignstatusQuery({ campaignid: selectedCampaign || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize, status: metricStatus })
    /* for image updation frequently */
    const { data, error, refetch } = useGetgeneratedBlogListingsQuery({
        promptid: promptid,
        userid: user_email || '',
        limitval: pageSize,
        skipval: (currentPage - 1) * pageSize
    });
    /*  const { data, error, refetch } = useGetBlogsByCampaignQuery({
         campaignid: selectedCampaign || '',
         limitval: pageSize,
         skipval: (currentPage - 1) * pageSize
     }); */
    /*  */
    // let totalPages = Math.ceil((getBlogsCount?.data ?? 0) / pageSize);
    // const [totalPages, setTotalPages] = useState(Math.ceil((getCampaignBlogsCount?.data ?? 0) / pageSize));
    const [totalPages, setTotalPages] = useState(Math.ceil((getBlogsCount?.data ?? 0) / pageSize));
    const [totalPagesFiltered, setTotalPagesFiltered] = useState(totalPages);
    type IntervalId = ReturnType<typeof setInterval>;
    const intervalIdRef = useRef<IntervalId | null>(null);
    const [query, setQuery] = useState('');
    const [images, setImages] = useState<UnsplashImage[]>([]);
    const [unsplashSearch, setUnsplashSearch] = useState<boolean>(false);
    const [findText, setFindText] = useState('');
    const [replaceText, setReplaceText] = useState('');
    const [updatedText, setUpdatedText] = useState('');
    const [matches, setMatches] = useState<number[]>([]);
    const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(-1);
    /* for image updation frequently */
    /*  useEffect(() => {
         const intervalId = setInterval(() => {
             refetch(); 
         }, 5000);
         return () => clearInterval(intervalId);
     }, []); */

    const campaignRes = async () => {
        try {
            const response = await campaignListMutation({ email: user_email || '' });
            if ('data' in response) {
                setCampaignListDatas(response.data);
                console.log('campaign list response=>', response.data)
            } else {
                //console.error('Error fetching campaign List:', response.error);
            }
        } catch (error) {
            //console.log('Error fetching campaign List:', error);
        }
    }


    const handlecampaignSelectionChange = (event: any, fromCampaign: any, fromUpdatedCampaign: any) => {
        localStorage.removeItem('publishingCampId');
        const selectedValue = event.target.value || fromCampaign || fromUpdatedCampaign;
        // setSelectedCampaign(event.target.value);
        setSelectedCampaign(selectedValue);
        //console.log('Selected campaign:', event.target.value);//getting selected campaign id here
        // fetchingPublishDatas(event.target.value);
        fetchingPublishDatas(selectedValue);
        localStorage.setItem('campId', selectedValue);
        // localStorage.setItem('selectedCamp', selectedValue);
    };

    useEffect(() => {
        const publishingblogCampId = localStorage.getItem('publishingCampId');
        if (publishingblogCampId) {
            setSelectedCampaign(publishingblogCampId);
            fetchingPublishDatas(publishingblogCampId);
        }
        else if (FromCampaign) {

            setSelectedCampaign(FromCampaign);
            fetchingPublishDatas(FromCampaign);
            localStorage.setItem('campId', FromCampaign);

            const count = 0;
            localStorage.setItem('refreshCount', count.toString());

            const refreshPage = () => {
                const refreshCountStr = localStorage.getItem('refreshCount');
                const refreshCountt = refreshCountStr !== null ? parseInt(refreshCountStr) : 0;

                if (refreshCountt < 1) {
                    setTimeout(() => {
                        localStorage.setItem('refreshCount', (refreshCountt + 1).toString());
                        // window.location.reload();
                        setSelectedCampaign(FromCampaign);
                        fetchingPublishDatas(FromCampaign);

                        // processBlogs();

                    }, 20000);
                }
            };
            refreshPage();

            // **Set FromCampaign to null**
            navigate(location.pathname, {
                replace: true,
                state: { ...location.state, FromCampaign: null },
            });

        } else if (FromUpdatedCampaign) {
            setSelectedCampaign(FromUpdatedCampaign);
            fetchingPublishDatas(FromUpdatedCampaign);
            localStorage.setItem('campId', FromUpdatedCampaign);
        } else if (campaignListDatas && campaignListDatas.length > 0 /* && !selectedCampaign */) {
            const savedCampId = localStorage.getItem('campId');
            if (!savedCampId) {
                // Only set to the 0th index if no other campaign was previously selected
                setSelectedCampaign(campaignListDatas[0].campaign_id);
                fetchingPublishDatas(campaignListDatas[0].campaign_id);
                localStorage.setItem('campId', campaignListDatas[0].campaign_id);
            } else {
                setSelectedCampaign(savedCampId);
                fetchingPublishDatas(savedCampId);
            }
            /* setSelectedCampaign(campaignListDatas[0].campaign_id);
            fetchingPublishDatas(campaignListDatas[0].campaign_id);
            localStorage.setItem('campId', campaignListDatas[0].campaign_id); */

        }
    }, [campaignListDatas, FromCampaign, FromUpdatedCampaign, location, navigate])

    useEffect(() => {
        campaignRes();
    }, []);

    const checkForUnupdatedImages = () => {
        //const UnupdatedImage = data && data.some((blog: any) => blog.image_updated === 'No');
        //if (UnupdatedImage) {
        refetch();
        timestampInMilliseconds = Date.now();
        milliSeconds = Math.floor(timestampInMilliseconds / 1000);
        setMilliSeconds(milliSeconds);
        //}
    }

    const BlogGenerationStatus = async () => {
        try {
            const response = await blogGenerationStatusMutation({ campaignid: localStorage.getItem('campId') || FromCampaign/* selectedCampaign || FromCampaign || '' */ });

            if ('data' in response) {
                const currentStatus = response.data.status;
                const imageUpdated = Boolean(currentStatus);

                setGenerationStatus(response.data.status);
                if (response.data.status == false) {
                    //alert("varImageUpdated=="+varImageUpdated);
                    checkForUnupdatedImages();
                    fetchingPublishDatas(localStorage.getItem('campId') || '');


                }

                //check still image is not refreshed or not and call the list api again
                generatedBlogDetail.map((blog: any, index: any) => {
                    // Check some data within each blog
                    if (blog.blog_image_path.indexOf("/images/blogs12.png") > -1) {
                        checkForUnupdatedImages();
                        fetchingPublishDatas(localStorage.getItem('campId') || '');
                        console.log(`Blog ${blog.blog_image_path} is published:`);
                    }

                });



                // if (response.data.status == true && varImageUpdated == true){
                //     clearInterval(intervalIdRef.current as IntervalId);
                // }

                //console.log('generation status response=>', response.data.status)
            } else {
                //console.error('Error fetching     blog generation status:', response.error);
            }
        } catch (error) {
            //console.log('Error fetching blog generation status:', error);
        }
    }

    useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            BlogGenerationStatus();
        }, 5000);
        return () => clearInterval(intervalIdRef.current as IntervalId);
    }, []);

    useEffect(() => {
        let timerId: any;
        const checkForUnupdatedImages = () => {
            const UnupdatedImage = data && data.some((blog: any) => blog.image_updated === 'No');
            if (UnupdatedImage) {
                refetch();
                timestampInMilliseconds = Date.now();
                milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                setMilliSeconds(milliSeconds);
            }
        }

        if (generationStatus === false) {

            checkForUnupdatedImages();

            if (error) {
                //console.log(error);
            }
            /* 
            checkForUnupdatedImages();
            setInterval(checkForUnupdatedImages, 5000);
            return () => clearInterval(timerId); */

            timerId = setInterval(() => {
                checkForUnupdatedImages();
                const unupdatedImageExists = data && data.some((blog: any) => blog.image_updated === 'No');
                if (!unupdatedImageExists) {
                    clearInterval(timerId);
                }
            }, 5000);
        }
        return () => clearInterval(timerId);
    }, [data, error, refetch]);

    /* useEffect(() => {
        if (error) {
            console.error('Error fetching blogs:', error);
        }

        if (data) {
            data.forEach((blog: IGetBlogsAdmin) => {
                if (blog.image_updated === 'Yes') {
                    setGeneratedBlogDetail(blog);
                } else {
                    console.log(`Image for blog with ID ${blog._id} is not updated.`);
                }
            });
        }
    }, [data, error]); */
    /*  */

    useEffect(() => {

        if (getBlogs && getBlogs.data) {



            const updatedBlogs = getBlogs.data.map((blog: any, index: any) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            if (selectedBlogTitle) {
                const filtered = updatedBlogs.filter((blog: any) => blog.blog_title === selectedBlogTitle);
                const paginatedBlogs = filtered.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                );
                setGeneratedBlogDetail(paginatedBlogs);
                setTotalPagesFiltered(Math.ceil(filtered.length / pageSize));
                console.log('total pages if filtered', totalPagesFiltered);
                /* setGeneratedBlogDetail(filtered);

                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered); */
            } else {
                const paginatedBlogs = updatedBlogs.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                );
                setGeneratedBlogDetail(paginatedBlogs);
                setTotalPages(Math.ceil(getCampaignBlogsCount?.data / pageSize));
            }
        }
    }, [getBlogs, currentPage, selectedBlogTitle, pageSize]);

    /* const processBlogs = () => {
        if (selectedCampaign && getCampaignBasedBlogs && getCampaignBasedBlogs.data) {
            const updatedcampaignBlogs = getCampaignBasedBlogs.data.map((blog, index) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            //console.log('campaignBlogs=>', updatedcampaignBlogs);
            // Apply filtering if a blog title is selected
            if (selectedCampaign) {
                const filtered = updatedcampaignBlogs.filter((blog) => blog.product_id === selectedCampaign);
                setGeneratedBlogDetail(filtered);
                console.log('filtered val=>', filtered);
                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered);

            } else {
                setGeneratedBlogDetail(updatedcampaignBlogs);
                setTotalPages(Math.ceil(updatedcampaignBlogs.length / pageSize));
            }
        }
    }; */

    /* const processMetricStatusBasedBlogs = () => {
        if (selectedCampaign && getCampaignStatusBasedBlogs && getCampaignStatusBasedBlogs.data) {
            const updatedcampaignBlogs = getCampaignStatusBasedBlogs.data.map((blog, index) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            //console.log('campaignBlogs=>', updatedcampaignBlogs);
            // Apply filtering if a blog title is selected
            if (selectedCampaign) {
                const filtered = updatedcampaignBlogs.filter((blog) => blog.product_id === selectedCampaign);
                setGeneratedBlogDetail(filtered);
                console.log('filtered val=>', filtered);
                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered);

            } else {
                setGeneratedBlogDetail(updatedcampaignBlogs);
                setTotalPages(Math.ceil(updatedcampaignBlogs.length / pageSize));
            }
        }
    }; */

    /* useEffect(() => {
        if (metricStatus) {
            processMetricStatusBasedBlogs();
        } else {
            setTotalMetric('Total');
            processBlogs();
        }
    }, [getCampaignBasedBlogs, getCampaignStatusBasedBlogs, currentPage, selectedCampaign, pageSize, metricStatus]); */

    const handlePublishedClick = () => {
        setMetricStatus('Live');
        setTotalMetric('');
    };

    const handleUnpublishedClick = () => {
        setMetricStatus('draft');
        setTotalMetric('');
    };

    /* const handleTotalMetricClick = () => {
        setTotalMetric('Total');
        setMetricStatus('');
        processBlogs();
    } */

    /* useEffect(() => {
        processBlogs();
    }, [getCampaignBasedBlogs, currentPage, selectedCampaign, pageSize, generatedBlogDetail]); */


    useEffect(() => {
        if (getProducts) {
            setProductDetail(getProducts.data);

            const productDet = getProducts.data?.map((product: any, index: any) => ({
                ...product
            }));

            setProductDetail(productDet)

        }
    }, [getProducts]);


    useEffect(() => {
        //console.log("productDetail....");

        const producturl = productDetail ? productDetail.map((product: any) => product.url ?? '') : '';
        setProductURL(producturl[0]);

    }, [productDetail])

    const dropdownList = generatedBlogDetail ? generatedBlogDetail.map((blog: any) => blog.blog_title || blog.blog_title_draft) : [];

    const handleToggleClick = () => {
        navigate('/codegenie/blogs-list');
    }
    const handleToggleClick1 = () => {
        localStorage.setItem('activeItem', 'blogs');
        navigate('/codegenie/generated-blogs');
    }

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setImageFile(file);

            const objectUrl = URL.createObjectURL(file);
            setBlogImg(objectUrl);
            setChoosingImageModal(false);
        }
    };

    const accessKey = 'hT9C64LpMAlpDRMJ-Wbt8IBbUKICCu7JfbLjxmebTvo';

    const handleAuthorImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setAuthorImgFile(file);

            const objectUrl = URL.createObjectURL(file);
            setAuthorImg(objectUrl);
        }
    };

    const handleSearch = async () => {
        setUnsplashSearch(true);
        if (!query) {
            toast.error('Please enter any value to search');
        } else {
            try {
                // const response = await axios.get(`https://api.unsplash.com/search/photos`, {
                const response = await axios.get(`https://api.unsplash.com/search/photos?client_id=${accessKey}`, {
                    params: { query, per_page: 20 },
                    /* headers: {
                        Authorization: `Client-ID ${accessKey}`
                    } */
                });
                setImages(response.data.results);
            } catch (error) {
                console.error("Error fetching images from Unsplash", error);
            }
        }
    };

    const horizontalImages = images.filter(image => image.width > image.height);

    const handleSelectUnsplashImage = async (imageUrl: any, imageid: any) => {
        setSelectedUnsplashImg(imageid);
        setBlogImg(imageUrl);

        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], "unsplash_image.jpg", { type: blob.type });

        setImageFile(file);
    };

    useEffect(() => {
        return () => {
            if (blogImg) {
                URL.revokeObjectURL(blogImg);
            }
        };
    }, [blogImg]);

    const handleUnsplashselectionChange = () => {
        if (!blogImg) {
            toast.error('Please select any image!');
        } else {
            setOpenUnsplash(false);
            setChoosingImageModal(false);
        }
    }

    const handlesaveDraft = async () => {
        /* const updatedBlog = await blogDetailsDraftMutation({
            id: selectedBlog?._id || '',
            blog_title: updateblogs.blog_title,
            blog_image_string: updateblogs.blog_image_string,
            blog_image: updateblogs.blog_image_string,
            blog_summary: updateblogs.blog_summary,
            blog_det: updateblogs.blog_det,
            url: updateblogs.url,
            author: updateblogs.author,
            author_image: updateblogs.author_image,
            author_url: updateblogs.author_url,
            cta_label: updateblogs.cta_label,
            cta_url: updateblogs.cta_url
        });
        console.log(updatedBlog); */
        setIsDraftOrPublishLoading(true);
        const formData = new FormData();
        formData.append('id', blog.id || '');
        formData.append('blog_title', updateblogs.blog_title);
        formData.append('blog_image_string', updateblogs.blog_image_string);
        if (imageFile) {
            formData.append('blog_image', imageFile);
        }
        formData.append('blog_summary', updateblogs.blog_summary);
        formData.append('blog_det', updateblogs.blog_det);
        formData.append('url', updateblogs.url);
        formData.append('cta_url', updateblogs.cta_url);
        formData.append('cta_label', updateblogs.cta_label);
        formData.append('author', updateblogs.author);
        // formData.append('author_image', updateblogs.author_image);
        if (authorImgFile) {
            formData.append('autor_image', authorImgFile);
        }
        formData.append('author_url', updateblogs.author_url);
        formData.append('videotag', updateblogs.videotag);

        /* if ('data' in updatedBlog) {
            const datas = updatedBlog.data.status;
            console.log(datas);
            if (datas === 'success') {
                toast.success('Blog saved to draft Successfully!!');
                window.location.reload();
            } else {
                toast.error('Something went wrong!');
            }
        } */
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/prompts/savecontentasdraft', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                refetch();
                timestampInMilliseconds = Date.now();
                milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                setMilliSeconds(milliSeconds);
                // toast.success('Blog saved to draft Successfully!!');
                toast.success('Blog saved Successfully!!');
                // window.location.reload();
            } else {
                toast.error('Something went wrong!');
            }
            setOPenEdit(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        } finally {
            setIsDraftOrPublishLoading(false);
        }
    };

    const handlePublished = async () => {
        //console.log('selectedCamp=>', selectedCampaign);
        localStorage.setItem('publishingCampId', selectedCampaign);
        /* const updatedBlog = await blogDetailsPublishMutation({
            id: selectedBlog?._id || '',
            blog_title: updateblogs.blog_title,
            blog_image_string: updateblogs.blog_image_string,
            blog_image: imageFile,
            blog_summary: updateblogs.blog_summary,
            blog_det: updateblogs.blog_det,
            url: updateblogs.url,
            cta_url: updateblogs.cta_url,
            cta_label: updateblogs.cta_label,
            author: updateblogs.author,
            author_image: updateblogs.author_image,
            author_url: updateblogs.author_url
        }); */
        setIsDraftOrPublishLoading(true);
        const formData = new FormData();
        formData.append('id', selectedBlog?._id || '');
        formData.append('blog_title', updateblogs.blog_title);
        formData.append('blog_image_string', updateblogs.blog_image_string);
        if (imageFile) {
            formData.append('blog_image', imageFile);
        }
        formData.append('blog_summary', updateblogs.blog_summary);
        formData.append('blog_det', updateblogs.blog_det);
        formData.append('url', updateblogs.url);
        formData.append('cta_url', updateblogs.cta_url);
        formData.append('cta_label', updateblogs.cta_label);
        formData.append('author', updateblogs.author);
        // formData.append('author_image', updateblogs.author_image);
        if (authorImgFile) {
            formData.append('autor_image', authorImgFile);
        }
        formData.append('author_url', updateblogs.author_url);
        formData.append('videotag', updateblogs.videotag);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/prompts/publishcontent', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                toast.success('Blog Published Successfully!!');
                refetch();
                timestampInMilliseconds = Date.now();
                milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                setMilliSeconds(milliSeconds);
                // window.location.reload();

            } else {
                toast.error('Something went wrong!');
            }


            /*  if ('data' in updatedBlog) {
                 const datas = updatedBlog.data.status;
                 if (datas === 'success') {
                     toast.success('Blog Published Successfully!!');
                     window.location.reload();
                 } else {
                     toast.error('Something went wrong!');
                 }
             } */
            setOPenEdit(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        } finally {
            setIsDraftOrPublishLoading(false);
        }
    };

    // Pagination


    //


    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: useremail || getCookie('user_email') || '' });
            if ('data' in response) {
                setUserDets(response.data.data);
                console.log('userDetails=>', response.data.data);
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (userDets?.free_plan === 1) {
            setHelperModal(true);
        }
    }, [userDets?.free_plan]);

    const fetchingPublishDatas = async (campaignId: string) => {
        try {
            const responseCount = await publishUnpublishCountMutation({ email: useremail || getCookie('user_email') || '', campaignid: campaignId });
            //console.log('publish count=>', responseCount);
            if ('data' in responseCount) {
                const datas = responseCount.data;
                //console.log(datas);
                setPublishedUnpublishedCount(datas);
            }
        } catch (error) {
            //console.log('Error fetching publishing counts data:', error);
        }
    }
    useEffect(() => {
        fetchingPublishDatas('');
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            setFreePlan(localStorage.getItem('free_plan'));
            setSubscriptionLevel(localStorage.getItem('subscription_level'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    useEffect(() => {

        const { data } = getProductState;
        if (data?.plan_details) {
            //console.log(data?.plan_details);
            setPlans([...data?.plan_details]);
        }
        else
            setPlans([
                {
                    user: 1,
                    plan_name: "",
                    total_wishes: 0,
                    price: "",
                    period: "",
                    product_id: "",
                    price_id: "",
                    subscription_level: "",
                    feature: [],
                    contracts: 0,
                    type: "",
                    button_text: "",
                    save: ''
                },
            ]);
    }, [getProductState]);
    const [isWideScreen, setIsWideScreen] = useState(false);

    /* 1366x768 resolution for the section(what if you had 1000 AI agents.) */
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth <= 1366 && window.innerHeight <= 768);
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*  */
    useEffect(() => {
        localStorage.removeItem('prevImg');
    }, [])

    const handlePreviewBlogClick = async (blog_image: any, blog_id: string, productId: string) => {
        localStorage.setItem('prevImg', '');
        if (blog_image) {
            setPrevImgLoader(true);
            const formData = new FormData();
            formData.append('blog_image', blog_image);
            formData.append('blog_id', blog_id);
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                //console.log('apiurl', apiUrl);
                const response = await axios.post(apiUrl + '/api/prompts/getBlogDetwihtImage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                //console.log('API response:', response.data.temp_image_path);

                localStorage.setItem('prevImg', response.data.temp_image_path);
            } catch (error) {
                //console.error('Error during API call:', error);
            } finally {
                setPrevImgLoader(false);
                window.open(`/PreviewBlogs/${updateblogs.id}?campId=${productId}`, '_blank');
            }
        } else {
            window.open(`/PreviewBlogs/${updateblogs.id}?campId=${productId}`, '_blank');
        }
    };

    const handleCTALabelChange = (e: any) => {
        const newValue = e.target.value;
        setPlanDetails((prevDetails: any) => ({
            ...prevDetails,
            cta_lable: newValue
        }));
        setUpdateBlogs(prevState => ({
            ...prevState,
            cta_label: newValue
        }));
    }

    const handleCTALinkChange = (e: any) => {
        const newValue = e.target.value;
        setPlanDetails((prevDetails: any) => ({
            ...prevDetails,
            cta_link: newValue
        }));
        setUpdateBlogs(prevState => ({
            ...prevState,
            cta_url: newValue
        }));
    }

    const handlevideochange = (event: any) => {
        const newVideotag = event.target.value;
        setUpdateBlogs((prevBlogs) => ({
            ...prevBlogs,
            videotag: newVideotag,
        }));
    };

    const modifyImageUrl = (url: any) => {
        let modifiedUrl = new URL(url);

        modifiedUrl.searchParams.set('fit', 'none');

        modifiedUrl.searchParams.set('w', '1152');

        modifiedUrl.searchParams.set('h', '500');

        return modifiedUrl.toString();
    };

    const handleMenuOpenClick = (index: any) => {
        if (isMenuDropdownOpen === index) {
            setIsMenuDropdownOpen(null);
        } else {
            setIsMenuDropdownOpen(index);
        }
    }

    const youtubeplaceholderText = 'https://www.youtube.com/watch?v=X7sEkixKk4g&t=1s';

    const handleContinueRegClick = () => {
        navigate('/pricing', { state: { 'campaignIdFromBL': localStorage.getItem('campId'), 'from_page': 'campaign_list', 'useremail': userDets?.email || getCookie('user_email') || '' } });
    }

    useEffect(() => {
        // Initialize updatedText when selectedCampaign changes
        const selectedCampaignData = campaignListDatas?.find((campaign: any) => campaign.campaign_id === selectedCampaign);
        if (selectedCampaignData) {
            setUpdatedText(selectedCampaignData.product_desc);
        }
    }, [selectedCampaign, campaignListDatas]);

    const handleTranscribedTextAreaChange = (value: any) => {
        const updatedCampaignList = campaignListDatas.map((campaign: any) => {
            if (campaign.campaign_id === selectedCampaign) {
                return { ...campaign, product_desc: value };
            }
            return campaign;
        });
        setCampaignListDatas(updatedCampaignList);
        setUpdatedText(value);
    }

    console.log("Selected Campaign:", selectedCampaign);

    const handleTranscribedSaveClick = async (campaignid: any, productsdesc: any) => {
        setIsTranscribeEditable(false);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.get(
                `${apiUrl}/api/products/update_original_text?campaignid=${campaignid}&new_product_desc=${productsdesc}`,
            );
            console.log('saved Transcribed det=>', response.data);
            if (response.data.status === 'success') {
                toast.success(response.data.data);
                // campaignRes();
                window.location.reload();
            } else {
                toast.error('updating failed!');
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Handle Find and Replace
    const handleFind = () => {
        if (findText) {
            if (matches.length === 0) {
                // Find all matches
                const indices: number[] = [];
                const regex = new RegExp(findText, "gi");
                let match;

                while ((match = regex.exec(updatedText)) !== null) {
                    indices.push(match.index);
                }

                if (indices.length > 0) {
                    setMatches(indices);
                    setCurrentMatchIndex(0);
                }
            } else {
                // Move to the next match
                const nextIndex = (currentMatchIndex + 1) % matches.length;
                setCurrentMatchIndex(nextIndex);
            }
        }
    };

    const handleReplace = () => {
        if (findText && currentMatchIndex !== -1) {
            const start = matches[currentMatchIndex];
            const end = start + findText.length;

            const updatedContent =
                updatedText.substring(0, start) +
                replaceText +
                updatedText.substring(end);

            setUpdatedText(updatedContent);
            handleTranscribedTextAreaChange(updatedContent);

            // Reset matches after replace
            setMatches([]);
            setCurrentMatchIndex(-1);
        }
    };

    const handleReplaceAll = () => {
        if (findText) {
            const updatedContent = updatedText.replace(new RegExp(findText, "g"), replaceText);
            setUpdatedText(updatedContent);
            handleTranscribedTextAreaChange(updatedContent);

            // Reset matches
            setMatches([]);
            setCurrentMatchIndex(-1);
        }
    };

    const outputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (outputRef.current) {
            const highlightedElement = outputRef.current.querySelector(`#current-match`);
            if (highlightedElement) {
                highlightedElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [currentMatchIndex]);

    const getHighlightedText = () => {
        if (!findText || matches.length === 0) {
            // Return the plain text with escaped HTML to prevent issues
            return { __html: updatedText.replace(/\n/g, '<br>') };
        }

        // Build the highlighted content using a safe approach
        const regex = new RegExp(`(${findText})`, 'gi');
        const highlightedContent = updatedText.replace(regex, (match, group, offset) => {
            const isCurrentMatch = matches[currentMatchIndex] === offset;
            return `<span id="${isCurrentMatch ? "current-match" : ""}" style="background-color: ${isCurrentMatch ? "yellow" : "lightgray"
                }; font-weight: ${isCurrentMatch ? "bold" : "normal"}; color: ${isCurrentMatch ? "#000" : "#000"};">${match}</span>`;
        });

        return { __html: highlightedContent.replace(/\n/g, '<br>') };
    };

    console.log(campaignListDatas);

    const clearCookies = () => {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [name] = cookie.split("=");
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
    };

    const handleItemClick = (itemName: any) => {
        localStorage.setItem('activeItem', itemName);
        setActiveItem(localStorage.getItem('activeItem') ? localStorage.getItem('activeItem') : itemName);
        navigate('/codegenie/generated-blogs');
        if (itemName === 'upgrade') {
            const value = {
                _id: "",
                product_name: "CodeGenie",
                product_module: genieSelector.module ? genieSelector.module : "",
                userid: "",
                productid: ""
            };
            getProduct(value);
            localStorage.setItem('fromUpgrade', 'true');
            navigate('/pricing', { state: { campIdFromUpgrade: localStorage.getItem('campId') || ''/* selectedCampaign */, from_page: 'blog_listing' } });
        } else if (itemName === 'logout') {
            clearCookies();
            localStorage.setItem("logged_in", "false");
            //console.log("loggedin:" + localStorage.getItem("logged_in"));
            dispatch(logout());
            var module = localStorage.getItem("module");
            console.log("module111...." + module);

            toast.success("Logout success");
            // localStorage.removeItem("payment");
            localStorage.removeItem("msg");
            localStorage.removeItem("complexity");
            localStorage.removeItem("module");
            localStorage.removeItem("subscription_level");
            localStorage.removeItem("free_plan");
            // localStorage.removeItem("userRole");
            localStorage.removeItem("localStorage_msg");
            localStorage.removeItem("allSet");
            localStorage.removeItem("Blogs Generated");
            localStorage.removeItem("remaining_wish_count");
            localStorage.removeItem("userRole");
            localStorage.removeItem("payment");
            localStorage.removeItem("user_email");
            localStorage.removeItem("logged_in");
            localStorage.removeItem("prevImg");
            localStorage.removeItem("fromUpgrade");
            localStorage.removeItem("blogsCount");
            localStorage.removeItem("Generating Count");
            localStorage.removeItem("finalData");
            localStorage.removeItem("user_pass");
            localStorage.removeItem("fromNewcampaign");
            localStorage.removeItem("campaignid");
            localStorage.removeItem("returningtocampaign");
            localStorage.removeItem('sourceurl');
            localStorage.removeItem('campaignName');
            localStorage.removeItem('paymentOption');
            localStorage.removeItem('autoPublish');
            localStorage.removeItem('limitPublish');
            localStorage.removeItem('campId');
            localStorage.removeItem('campaignid');
            localStorage.removeItem('returningtocampaign');
            localStorage.removeItem('sourceurl');
            localStorage.removeItem('campaignName');
            localStorage.removeItem('paymentOption');
            localStorage.removeItem('autoPublish');
            localStorage.removeItem('limitPublish');
            localStorage.removeItem('smUrlpublish');
            localStorage.removeItem('addTags');
            localStorage.removeItem('captureLeads');
            localStorage.removeItem('newsLetter');
            localStorage.removeItem('twitterBtn');
            localStorage.removeItem('fbBtn');
            localStorage.removeItem('pintrestBtn');
            localStorage.removeItem('linkedinBtn');
            localStorage.removeItem('video');
            localStorage.removeItem('twitterurl');
            localStorage.removeItem('fburl');
            localStorage.removeItem('pintresturl');
            localStorage.removeItem('linkedinurl');
            localStorage.removeItem('owndomain');
            localStorage.removeItem('ChangepayMode');
            localStorage.removeItem('IdChangingMode');
            localStorage.removeItem('user_name');
            localStorage.removeItem('refreshCount');
            localStorage.removeItem('publishingCampId');
            localStorage.removeItem('Authorurl');
            localStorage.removeItem('Authorname');
            localStorage.removeItem('authorDefaultCheck');
            localStorage.removeItem('appsumo_coupon_code');
            localStorage.removeItem('podcastUrl');
            localStorage.removeItem('AlertMessage');
            localStorage.removeItem('CreatingfromLanding');
            localStorage.removeItem('UrlfromLanding');
            localStorage.removeItem('signedup');
            localStorage.removeItem('uploadedvideo');
            localStorage.removeItem('uploadedVideoName');
            localStorage.removeItem('uploadStatus');
            localStorage.removeItem('editedposthashtags');
            localStorage.removeItem('editedpostblogsummary');
            localStorage.removeItem('editedpostblogtitle');
            localStorage.removeItem('editedposturl');
            localStorage.removeItem('user_email');
            localStorage.removeItem('YoutubeUrlfromLanding');
            localStorage.removeItem('FirstLogin');
            localStorage.removeItem('DirectSignup');
            localStorage.removeItem('FromIndexPage');
            localStorage.removeItem('activeItem');
            localStorage.removeItem('UpdateduserImage');
            localStorage.removeItem('FromEditBlog');
            localStorage.removeItem('ErrorAlert');
            removeCookie('user_email');
            localStorage.clear();

            if (module == process.env.REACT_APP_MAIN_PRODUCT_NAME)
                navigate("/Anycode_landing");
            else
                navigate("/");
        }
    };

    /* const handleSelectionChange = (range: any) => {
        if (range && range.length > 0) {
            setShowToolbar(true); // Show toolbar when text is selected
            setEditorRange(range); // Save the selection range
        } else {
            setShowToolbar(false); // Hide toolbar when no text is selected
        }
    };

    const applyFormatting = (format: string, value: any) => {
        if (editorRange && quillRef.current) {
            const quill = quillRef.current.getEditor(); // Access the Quill instance
            quill.formatText(editorRange.index, editorRange.length, format, value);
            setShowToolbar(false); // Optionally hide toolbar after formatting
        }
    };

    const handleEditorChange = (content: string) => {
        setUpdateBlogs({ ...updateblogs, blog_title: content });
    }; */

    const handleEditorChange = (value: string, editorType: 'title' | 'body') => {
        if (editorType === 'title') {
            setUpdateBlogs((prev) => ({ ...prev, blog_title: value }));
        } else if (editorType) {
            setUpdateBlogs((prev) => ({ ...prev, blog_det: value }));
        }
    };

    const handleSelectionChange = (range: any, editorType: 'title' | 'body') => {
        const quillRef = editorType === 'title' ? quillTitleRef : quillBodyRef;
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            if (range) {
                const bounds = quill.getBounds(range);
                const editorContainer = quill.root.getBoundingClientRect();
                // Set the position of the custom toolbar
                setToolbarPosition({
                    top: bounds.top + editorContainer.top + window.scrollY - 40, // Adjust for page scrolling and offset above the selection
                    left: bounds.left + editorContainer.left + window.scrollX, // Adjust for horizontal position
                });
                /*  setToolbarPosition({
                     top: editorType === 'title' ?  205 :  578,
                     left: bounds.left,
                 }); */
                setToolbarVisible(true);
            }/*  else {
                setToolbarVisible(false); // Hide toolbar when no selection
            } */
        }
    };

    const handleFocus = (editorType: 'title' | 'body') => {
        const quillRef = editorType === 'title' ? quillTitleRef : quillBodyRef;
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const bounds = quill.getBounds(0);
            setToolbarPosition({
                top: editorType === 'title' ? 205 : 578,
                left: bounds.left,
            });
            setToolbarVisible(true);
        }
    };

    useEffect(() => {
        const handleTitleSelectionChange = (range: any) => handleSelectionChange(range, 'title');
        const handleBodySelectionChange = (range: any) => handleSelectionChange(range, 'body');

        if (quillTitleRef.current) {
            const quillTitle = quillTitleRef.current.getEditor();
            quillTitle.on('selection-change', handleTitleSelectionChange);
        }

        if (quillBodyRef.current) {
            const quillBody = quillBodyRef.current.getEditor();
            quillBody.on('selection-change', handleBodySelectionChange);
        }

        return () => {
            if (quillTitleRef.current) {
                const quillTitle = quillTitleRef.current.getEditor();
                quillTitle.off('selection-change', handleTitleSelectionChange); // Remove using the named handler
            }

            if (quillBodyRef.current) {
                const quillBody = quillBodyRef.current.getEditor();
                quillBody.off('selection-change', handleBodySelectionChange); // Remove using the named handler
            }
        };
    }, []);

    useEffect(() => {
        if (!initialContent.current && updateblogs?.blog_det) {
            const processedContent = updateblogs.blog_det.replace(/(<h3>)/g, '<p><br></p>$1');
            setBlogContent(processedContent);
            initialContent.current = true;
        }
    }, [updateblogs.blog_det]);

    const handleBlogContentChange = (value: any) => {
        setBlogContent(value);
        handleEditorChange(value, 'body');
    };

    return (
        <>
            <div style={{ overflowX: 'hidden' }}>
                {/* header */}
                <div className="row" style={{ marginTop: '1%' }}>
                    <div className="col-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                        <img src={oceshaLogo} alt="" />
                        <Tooltip title="Click here for help video">
                            <img src={youtubeIco1} style={{ cursor: 'pointer', height: '40px', marginTop: '-9px', marginLeft: '-9px' }}
                                onClick={() => {
                                    setHelperVideoSrc("/images/Overview.mp4");
                                    setHelperPopup(true);
                                }} />
                        </Tooltip>
                    </div>
                    <div className="col-10" style={{ display: 'flex', justifyContent: 'flex-end', gap: '1px' }}>
                        <Ocesha_header handleItemClick={handleItemClick} />
                    </div>
                </div>
                <hr />
                {/* end header */}

                <div style={{ background: '#fff', paddingLeft: '6%', paddingRight: '6%', marginRight: '8%', marginLeft: '8%' }}>
                    {/* blog header */}
                    <div className="row" style={{ /* paddingLeft: '10%', paddingRight: '10%', */ marginTop: '2%', paddingTop: '1%' }}>
                        <div className="col-2">
                            <p style={{ fontWeight: 'bold', fontSize: '32px', color: '#000' }}>Edit Blog</p>
                        </div>
                        <div className="col-10" style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Tooltip title="Click here for help video">
                                <img src={youtubeIco}
                                    onClick={() => {
                                        setHelperVideoSrc("/images/Blog_Editor.mp4");
                                        setHelperPopup(true);
                                    }} style={{ cursor: 'pointer', height: '40px'/* , marginTop: '8px' */ }} />
                            </Tooltip>
                            <button className="button_leniar" style={{ fontWeight: 'normal', fontSize: '14px', width: '16%', background: 'none', border: '1px solid #4BA5EB', borderRadius: '26px', color: '#000' }}
                                onClick={() => {
                                    localStorage.setItem('activeItem', 'blogs');
                                    navigate('/codegenie/generated-blogs');
                                    localStorage.setItem('FromEditBlog', 'true');
                                }}>Back to blog list</button>
                            <button className="button_leniar" style={{ fontWeight: 'normal', fontSize: '14px', width: '9%', borderRadius: '26px' }} onClick={handlesaveDraft}>{isDraftOrPublishLoading ? <CircularProgress /> : 'Save'}</button>
                        </div>
                        <hr style={{ color: '#000' }} />
                    </div>
                    {/* end blog header */}

                    <div className="row" /* style={{ paddingLeft: '10%', paddingRight: '10%' }} */>
                        {/* <input
                    type="text"
                    value={updateblogs.blog_title}
                    onChange={(e: any) => setUpdateBlogs({ ...updateblogs, blog_title: e.target.value })}
                    style={{
                        color: '#fff !important',
                        background: 'none',
                        border: 'none',
                        fontSize: '32px',
                        fontWeight: 'bold'
                    }}
                /> */}
                        <Grid item xs={12} lg={8} style={{ /* borderRight: "1px solid #CBCBCB", */ padding: '1%' }}>
                            <Stack padding="1%">
                                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '16px' }}>
                                    <span
                                        style={{
                                            fontSize: '12px', // Small font size for the label
                                            color: '#000',
                                            marginRight: '10px', // Space between label and editor
                                        }}
                                    >
                                        Title
                                    </span>
                                    <div className="title-editor" style={{ position: 'relative', marginBottom: '20px', /* border: '1px solid #ccc', */ padding: '10px', borderLeft: '1px solid #000' }}>

                                        <ReactQuill
                                            ref={quillTitleRef}
                                            value={updateblogs.blog_title}
                                            onChange={(value) => handleEditorChange(value, 'title')}
                                            onFocus={() => handleFocus('title')}
                                            theme="snow"
                                            readOnly={false}
                                            // placeholder="Edit your blog title here..."
                                            style={{
                                                width: '100%',
                                                background: 'none',
                                                border: 'none',
                                                outline: 'none',
                                                caretColor: '#000',
                                                color: '#000',
                                                fontWeight: 'bold',
                                                lineHeight: '1.5',
                                            }}
                                            modules={{ toolbar: false }} // Disable default toolbar
                                        />
                                    </div>
                                </div>

                                <div style={{ position: 'relative', padding: '10px' }}>
                                    {(blogImg || updateblogs.blog_image_path) && (
                                        <img src={blogImg ? blogImg : (`${updateblogs.blog_image_path}?t=${timestampInSeconds}`)} alt="Uploaded" title="Click here to edit image" style={{ maxWidth: '95%', marginLeft: '4%', height: '100%', borderRadius: '12px' }} onClick={() => setChoosingImageModal(true)} />
                                    )}
                                </div>


                                <div className="body-editor" style={{ position: 'relative', /* border: '1px solid #ccc', */ padding: '10px', marginLeft: '3%' }}>
                                    <ReactQuill
                                        ref={quillBodyRef}
                                        value={blogContent}
                                        onChange={handleBlogContentChange}
                                        onFocus={() => handleFocus('body')}
                                        theme="snow"
                                        readOnly={false}
                                        // placeholder="Edit your blog content here..."
                                        style={{
                                            width: '100%',
                                            background: 'none',
                                            border: 'none',
                                            outline: 'none',
                                            caretColor: '#000',
                                            color: '#000',
                                            lineHeight: '1.5',
                                        }}
                                        modules={{ toolbar: false }} // Disable default toolbar
                                    />
                                </div>

                                {toolbarVisible && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: `${toolbarPosition.top}px`,
                                            left: `${toolbarPosition.left}px`,
                                            zIndex: 10,
                                            // backgroundColor: '#36393F',
                                            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                            padding: '5px 10px',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            gap: '5px',
                                        }}
                                    >
                                        <Button
                                            style={{
                                                width: '100%%',
                                                background: '#36393F',
                                                color: '#CACBCC',
                                                display: 'flex',
                                                gap: '20px',
                                                fontWeight: 'bold',
                                                cursor: 'none'
                                            }}
                                        >
                                            {/* Bold */}
                                            <span onClick={() => document.execCommand('bold')} style={{ cursor: 'pointer' }}>
                                                B
                                            </span>

                                            {/* Italic */}
                                            <span onClick={() => document.execCommand('italic')} style={{ cursor: 'pointer', fontStyle: 'italic' }}>
                                                I
                                            </span>

                                            {/* Underline */}
                                            <span onClick={() => document.execCommand('underline')} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                                U
                                            </span>

                                            {/* Uppercase */}
                                            <span
                                                onClick={() => {
                                                    const selection = window.getSelection();
                                                    if (selection && selection.rangeCount > 0) {
                                                        const range = selection.getRangeAt(0);
                                                        const selectedText = range.toString();
                                                        if (selectedText) {
                                                            document.execCommand('insertText', false, selectedText.toUpperCase());
                                                        }
                                                    }
                                                }}
                                                style={{ cursor: 'pointer', textTransform: 'uppercase' }}
                                            >
                                                A
                                            </span>

                                            {/* Lowercase */}
                                            <span
                                                onClick={() => {
                                                    const selection = window.getSelection();
                                                    if (selection && selection.rangeCount > 0) {
                                                        const range = selection.getRangeAt(0);
                                                        const selectedText = range.toString();
                                                        if (selectedText) {
                                                            document.execCommand('insertText', false, selectedText.toLowerCase());
                                                        }
                                                    }
                                                }}
                                                style={{ cursor: 'pointer', textTransform: 'lowercase' }}
                                            >
                                                a
                                            </span>

                                            {/* Link */}
                                            <span
                                                onClick={() => {
                                                    const url = prompt('Enter the URL:');
                                                    if (url) {
                                                        document.execCommand('createLink', false, url);
                                                    }
                                                }}
                                                style={{ cursor: 'pointer', textDecoration: 'underline', color: '#4E94F3' }}
                                            >
                                                <LinkIcon />
                                            </span>
                                        </Button>
                                    </div>
                                )}
                                {/* <Typography style={{ color: '#fff' }}>Blog Title</Typography>
                            <TextField
                                InputProps={{
                                    style: customInputStyle,
                                }}
                                style={{ width: '100%', background: 'none', border: 'none', outline: 'none', caretColor: '#fff', color: '#fff', fontSize: '40px', resize: 'none', overflow: 'hidden', lineHeight: '1.5', fontWeight: 'bold' }}
                                value={updateblogs.blog_title}
                                onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_title: e.target.value })} /> */}
                            </Stack>
                            <div style={{ display: 'none' }}>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#000' }}>Blog Image String</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%', display: 'none' }}
                                        value={updateblogs.blog_image_string}
                                        onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_image_string: e.target.value })}></TextField>
                                </Stack>
                            </div>
                            <Stack padding="1%" sx={{ display: 'none' }}>
                                <Typography style={{ color: '#000' }}>Blog Summary</Typography>
                                <TextField
                                    InputProps={{
                                        style: customInputStyle,
                                    }}
                                    style={{ color: '#fff', width: '100%' }}
                                    value={updateblogs.blog_summary}
                                    onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_summary: e.target.value })}
                                ></TextField>
                            </Stack>

                            <Stack padding="1%" style={{ display: 'none' }}>
                                <Typography style={{ color: '#fff' }}>Blog Image</Typography>
                                <div className="row">
                                    <div className="col-sm-5 col-md-5 col-lg-5">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ width: '100%' }}
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <div className="col-sm-7 col-md-7 col-lg-7" style={{ display: 'flex', alignItems: 'center', marginTop: isMobile ? '5%' : '' }}>
                                        <img src={unsplashImg} style={{ width: '4%'/* , marginTop: '2%' */, background: '#fff' }} onClick={() => setOpenUnsplash(true)} />
                                        <Typography sx={{ marginLeft: '10px' }}>Choose Image from Unsplash</Typography>
                                    </div>
                                </div>
                                {/*  {(blogImg || updateblogs.blog_image_path) && (
                                <img src={blogImg ? blogImg : updateblogs.blog_image_path} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                            )} */}
                            </Stack>

                            <Stack padding="1%" style={{ display: 'none' }}>
                                <Typography style={{ color: '#fff' }}>Blog Content</Typography>
                                <div style={{ height: '550px', overflow: 'auto', border: '1px solid #ccc' }}>
                                    <ReactQuill
                                        style={{ height: '93%' }}
                                        value={updateblogs.blog_det}
                                        onChange={(value: any) => setUpdateBlogs({ ...updateblogs, blog_det: value })}
                                        theme='snow'
                                        readOnly={false}
                                        modules={modules}
                                    />
                                </div>
                            </Stack>
                            <Stack padding="1%" sx={{ display: 'none' }}>
                                <Typography style={{ color: '#fff' }}>Blog URL</Typography>
                                <Grid container >
                                    <Grid item xs={12} sm={12} /* visibility="hidden" */>
                                        <TextField
                                            InputProps={{
                                                style: customInputStyle,
                                                readOnly: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={updateblogs && updateblogs?.url?.charAt(0) === '-' || updateblogs && updateblogs?.url?.charAt(updateblogs.url.length - 1) === '-' ? updateblogs.url.replace(/^-+|-+$/g, '') : updateblogs.url}

                                        ></TextField>
                                    </Grid>
                                </Grid>
                            </Stack>


                        </Grid>
                        <Grid item xs={12} lg={4} style={{ padding: '1%' }}>
                            <Stack padding="1%">
                                <Typography variant="h6" fontWeight="bold" style={{ color: '#000' }}>Article Details</Typography>
                            </Stack>
                            <Stack padding="1%" style={{ display: 'none' }}>
                                <Typography style={{ color: '#fff' }}>Type</Typography>
                                <Autocomplete
                                    options={staticTypeOptions}
                                    sx={{ border: '#CBCBCB' }}
                                    renderInput={(params) => (
                                        <TextField

                                            style={{ width: '100%', border: '1px solid #CBCBCB' }}
                                            {...params}
                                            placeholder="Blogs"
                                            label="Blogs"
                                            size="small"
                                        />
                                    )}
                                />
                            </Stack>
                            <div style={{ display: 'none' }}>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#fff' }}>Tags</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}></TextField>
                                </Stack>
                            </div>
                            <Stack padding="1%" style={{ display: 'none' }}>
                                <Typography style={{ color: '#fff' }}>Date of Published</Typography>
                                <TextField
                                    InputProps={{
                                        style: customInputStyle,
                                    }}
                                    value={updateblogs.publish_date}
                                    style={{ width: '100%' }}>{updateblogs?.publish_date?.split("T")[0]}</TextField>
                            </Stack>

                            <div>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#000' }}>
                                        We strongly recommend that you add your info here to display on your blogs<br /><br />
                                    </Typography>
                                    <Typography style={{ color: '#000' }}>Author</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}
                                        value={updateblogs.author/* planDetails?.name ? planDetails.name : updateblogs.author */}
                                        onChange={(e) => setUpdateBlogs({ ...updateblogs, author: e.target.value })}></TextField>
                                </Stack>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#000' }}>Author Profile Image</Typography>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        //onChange={handleImageChange}
                                        style={{ width: '100%', color: '#000' }}
                                        onChange={handleAuthorImageChange}
                                    />
                                    {/* 
                                                            unsplash images
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    value={query}
                                                                    onChange={(e) => setQuery(e.target.value)}
                                                                    placeholder="Search Unsplash images"
                                                                />
                                                                <button onClick={handleSearch}>Search</button>
                                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                    {images.map((image) => (
                                                                        <img
                                                                            key={image.id}
                                                                            src={image.urls.thumb}
                                                                            alt={image.alt_description}
                                                                            style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                                                            onClick={() => handleSelectUnsplashImage(image.urls.regular)}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div> */}

                                    {(authorImg || updateblogs.author_image || planDetails?.user_image) && (
                                        <img src={authorImg ? authorImg : planDetails?.user_image ? planDetails?.user_image : updateblogs?.author_image} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                                    )}
                                    {/* {userDets && userDets.user_image ? (
                                                                <img src={userDets?.user_image} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                                                            ) : (<><img src="https://images.code-genie.ai/images/no_user.jpg" alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} /><p>Upload an image here, <br /><a href="/codegenie/settings">Go to Settings.</a></p></>)} */}
                                    {/* {planDetails && planDetails.user_image ? (
                                                                <img src={planDetails?.user_image} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                                                            ) : (<><img src="https://images.code-genie.ai/images/no_user.jpg" alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} /><p>Upload an image here, <br /><a href="/codegenie/settings">Go to My Campaigns, Edit Campaign.</a></p></>)} */}
                                </Stack>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#000' }}>Author profile page (ex: linkedin url)</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}
                                        // value={updateblogs.author_url}
                                        value={updateblogs.author_url}
                                        placeholder={updateblogs.author_url ? updateblogs.author_url : 'Update your URL on settings'}
                                        onChange={(e) => { setUpdateBlogs({ ...updateblogs, author_url: e.target.value }) }}></TextField>
                                </Stack>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#000' }}>CTA Label</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}
                                        value={planDetails?.cta_lable || updateblogs.cta_label}
                                        onChange={handleCTALabelChange}/* onChange={(e) => setUpdateBlogs({ ...updateblogs, cta_label: e.target.value })} */></TextField>
                                </Stack>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#000' }}>CTA URL</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}
                                        value={planDetails?.cta_link || updateblogs.cta_url}
                                        onChange={handleCTALinkChange}/* onChange={(e) => setUpdateBlogs({ ...updateblogs, cta_url: e.target.value })} */></TextField>
                                </Stack>
                                <Stack padding="1%">
                                    <Typography style={{ color: '#000' }}>Add YouTube Video</Typography>
                                    <TextField
                                        placeholder={youtubeplaceholderText}
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}
                                        value={updateblogs?.videotag || ''}
                                        onChange={handlevideochange}
                                    ></TextField>
                                </Stack>
                            </div>
                        </Grid>
                    </div>
                </div>
            </div>
            {/* popups */}
            <Modal
                open={choosingImageModal}
                onClose={() => setChoosingImageModal(false)}
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "40%",
                        bgcolor: "#36393F",
                        // border: "2px solid #000",
                        // boxShadow: 24,
                        boxShadow: '0px 50px 50px rgba(0, 0, 0, 0.5)',
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    <IconButton
                        onClick={() => setChoosingImageModal(false)}
                        sx={{
                            position: 'absolute',
                            top: '6px',
                            right: '7px',
                            color: '#fff',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>{/* <InfoIcon sx={{ color: '#ffcc00' }} /><br /> */}Choose your image from below options</Typography>

                    <Stack flexDirection="row" sx={{ display: 'flex', gap: '20px', marginTop: '5%' }}>

                        {/*  <Button
                            variant="contained"
                            onClick={() => handleDelete(deleteBlogId)}>
                            Yes
                        </Button> */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onClick={handleImageClick}>
                            <ComputerIcon sx={{ fontSize: '3rem' }} />
                            <p style={{ color: '#fff' }}>Choose from folder</p>
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                style={{ /* width: '100%', */ display: 'none' }}
                                onChange={handleImageChange}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={unsplashImg} style={{ width: '28%', height: '48px',/* , marginTop: '2%' */ background: '#fff' }} onClick={() => setOpenUnsplash(true)} />
                            <p style={{ color: '#fff' }}>Choose from Unsplash</p>
                        </div>
                        {/* <Button
                            onClick={() => setDeleteModal(false)}
                            variant="contained"
                        sx={{ mr: 2, marginTop: '15%' }}
                        >
                            No
                        </Button> */}
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openUnsplash}
                onClose={() => setOpenUnsplash(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "90%" : "54%",
                        bgcolor: "background.paper",
                        // border: "2px solid #000",
                        // boxShadow: 24,
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 1.2)',
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '10px',
                        overflowY: isMobile ? 'auto' : 'hidden',
                        height: isMobile ? '600px' : '650px',
                        maxHeight: '650px'
                    }}
                >
                    <div className="row" style={{ width: '100%', marginTop: '5%' }}>
                        <div className=" col-sm-12 col-md-10 col-lg-10">
                            <TextField
                                placeholder="Search Unsplash images"
                                size="small"
                                InputLabelProps={{
                                    className: 'red-label'
                                }}
                                InputProps={{
                                    /*  ...params.InputProps, */
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiInputBase-input::placeholder': {
                                        color: 'white',
                                        opacity: 1
                                    },
                                    width: '100%'
                                }}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>

                        <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '2%' : '' }}>
                            <Button variant="contained" onClick={handleSearch} sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '' }}>Search</Button>
                        </div>
                        {/* <div className="col-md-8">
                        <Typography component="div">
                        <input
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Search Unsplash images"
                            style={{
                                width: '100%',
                                padding: '10px',
                                fontSize: '1rem',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                marginBottom: '1rem',
                            }}
                        />
                        </Typography>
                        </div>
                        <div className="col-md-4">
                        <Button variant="contained" onClick={handleSearch}>Search</Button>
                    </div> */}
                    </div>
                    {unsplashSearch === false ?
                        <div className="row" style={{ width: '100%' }}>
                            <div className="col-md-12">
                                <img src={unsplash_logo} style={{ width: '10%', display: 'block', margin: '0 auto', marginTop: '17%' }}></img>
                                <Typography variant="h4" sx={{ marginTop: '1%', textAlign: 'center' }}>Unsplash</Typography>
                                <Typography variant="h6" sx={{ marginTop: '1%', textAlign: 'center' }}>Try entering a search term, such as apple or daisy, to explore beautiful, high-resolution visuals.</Typography>{/* <br></br> */}

                                <Typography variant="h6" sx={{ textAlign: 'center' }}>Once you've found an image you're happy with, click on it to add it to your workspace.</Typography>
                                <Typography variant="h6" sx={{ textAlign: 'center' }} onClick={() => window.open('https://unsplash.com/', '_blank')}><a href="" style={{ textDecoration: 'none' }}>unsplash.com</a></Typography>
                            </div>
                        </div>
                        :
                        <div className="row" style={{ marginTop: '6%' }}>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: isMobile ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)',
                                gap: '10px',
                                justifyContent: 'center'
                            }}/* style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} */>
                                {horizontalImages.map((image) => (
                                    <div key={image.id} className="thumbnail-container">
                                        <img
                                            className="thumbnail"
                                            key={image.id}
                                            src={modifyImageUrl(image.urls.thumb)}
                                            alt={image.alt_description}
                                            style={{ width: '100px', /* height: '100px', */ cursor: 'pointer', border: image.id === selectedUnsplashImg ? '3px solid #4BA5EB' : '' }}
                                            onClick={() => handleSelectUnsplashImage(image.urls.regular, image.id)}
                                        />
                                        <img
                                            className="full-image"
                                            src={image.urls.regular}
                                            alt={image.alt_description}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                    <div className="row" style={{ marginTop: '5%' }}>
                        <div className={isMobile ? "col-6" : "col-sm-6 col-md-6 col-lg-6"}>
                            <Button variant="contained" sx={{ fontWeight: 'bold' }} onClick={handleUnsplashselectionChange/* () => setOpenUnsplash(false) */}>Select</Button>
                        </div>
                        <div className={isMobile ? "col-6" : "col-sm-6 col-md-6 col-lg-6"}>
                            <Button variant="contained" sx={{ fontWeight: 'bold' }} onClick={() => setOpenUnsplash(false)}>Close</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={helperPopup}
                /* onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setHelperModal(false);
                    }
                }} */
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "70%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    {/* <video
                                    controls
                                    style={{ width: '100%' }}
                                    src={helpervideosrc}
                                    autoPlay
                                /> */}
                    <iframe
                        width="100%"
                        height="500"
                        src={helpervideosrc}
                        title="Help Video"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    ></iframe>
                    <Stack flexDirection="row">
                        <Button
                            onClick={() => setHelperPopup(false)}
                            variant="contained"
                            sx={{ mr: 2, marginTop: '15%' }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            {/*  */}
        </>
    )
}

export default EditBlog;