import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import img54 from '../../assets/newimg/logo.png';
import { isBrowser, isMobile } from "react-device-detect";
import { TextField, Typography, CircularProgress, Grid, Paper, Stack, MenuItem, Select, Button } from "@mui/material";
import oracle from '../../assets/traffic_monster/oracle.png';
import hp from '../../assets/traffic_monster/hp.png';
import honda from '../../assets/traffic_monster/honda.png';
import american_red_cross from '../../assets/traffic_monster/american_red_cross.png';
import nv_energy from '../../assets/traffic_monster/nv_energy.png';
import avery_dennison from '../../assets/traffic_monster/avery_dennison.png';
import corning from '../../assets/traffic_monster/corning.png';
import '../traffic_monster/generated_blogs.css';
import testimonialImg1 from '../../assets/traffic_monster/testimonialImg1.png';
import testimonialImg3 from '../../assets/traffic_monster/testimonialImg3.png';
import testimonialImg4 from '../../assets/traffic_monster/testimonialImg4.jpg';
import { useGetgeneratedBlogListingsQuery, useGetrandomBlogListingsQuery } from '../../redux/api/promptApi';
import common_bg from '../../assets/podcast_monster/common_bg.png';
import { useGenerateBusinessBlogMutation } from '../../redux/api/promptApi';
import { toast } from "react-toastify";
import axios from 'axios';
import headerbg from '../../assets/ocesha_images/landing_new_podcast/podcast_ocesha_section1.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TrustPilotLogo from '../../assets/ocesha_images/trustpilot_logo.png';
import { StarHalf } from "@mui/icons-material";
import StarIcon from '@mui/icons-material/Star';
import last_section from '../../assets/ocesha_images/landing_new/last_section.png';
import PersonIcon from '@mui/icons-material/Person';
import ocesha_logo from '../../assets/ocesha_images/ocesha_logo.png';
import podcast_ocesha_section2 from '../../assets/ocesha_images/landing_new_podcast/podcast_ocesha_section2.png';
import key_benefits1_podcast from '../../assets/ocesha_images/landing_new_podcast/key_benefits1_popdcast.png';
import key_benefits2_podcast from '../../assets/ocesha_images/landing_new_podcast/key_benefits2_podcast.png';
import key_benefits3_podcast from '../../assets/ocesha_images/landing_new_podcast/key_benefits3_podcast.png';
import key_benefits4_podcast from '../../assets/ocesha_images/landing_new_podcast/key_benefits4_podcast.png';
import multilingual_section_podcast from '../../assets/ocesha_images/landing_new_podcast/multilingual_section_podcast.png';
import content_management_section_podcast from '../../assets/ocesha_images/landing_new_podcast/content_management_section_podcast.png';
import last_section_podcast from '../../assets/ocesha_images/landing_new_podcast/last_section_podcast.png';

const OceshaPodcastLandingNew = () => {

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndexTextTesti, setCurrentIndexTextTesti] = useState(0);
    const [isWideScreen, setIsWideScreen] = useState(false);
    const [isIpadMini, setIsIpadMini] = useState(false);
    const [randomInteger, setRandomInteger] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [blogs, setBlogs] = useState<any>();
    const [randomBlogs, setRandomBlogs] = useState<any>();
    const [httpsVal, setHttpsVal] = useState('https://');
    const [businessBlogMutation] = useGenerateBusinessBlogMutation();
    const [otherOption, setOtherOption] = useState(false);
    const [uploadVideo, setUploadVideo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedvideopath, setUploadedvideopath] = useState('');
    const [arrowClick1, setArrowClick1] = useState<any>();
    const [arrowClick2, setArrowClick2] = useState<any>();
    const [arrowClick3, setArrowClick3] = useState<any>();
    const [urlPrefix, setUrlPrefix] = useState<string>(/* 'https://' */'youtubeid');
    const [businessUrl, setBusinessUrl] = useState('');
    const [userBusinessText, setUserBusinessText] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [isScrapcheckloading, setIsScrapcheckloading] = useState<boolean>(false);
    const [contactname, setContactName] = useState<any>();
    const [contactemail, setContactEmail] = useState<any>();
    const [contactPhno, setContactPhNo] = useState<any>();
    const [contactMessage, setContactMessage] = useState<any>();
    const [isContacting, setIsContacting] = useState<boolean>(false);

    const sections = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.2, // threshold based on when want the animation to trigger
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible'); // Add 'visible' class when in view
                } else {
                    entry.target.classList.remove('visible'); // Remove class when out of view
                }
            });
        }, observerOptions);

        sections.current.forEach((section) => {
            if (section instanceof Element) {
                observer.observe(section);
            }
        });

        return () => {
            sections.current.forEach((section) => {
                if (section instanceof Element) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    const promptid = params.get('promptid') ?? "";

    const videotestimonialData = [
        {
            // image: testimonial_videoImg1,
            video: "https://www.youtube.com/embed/mEyC24nC73k",
            name: "Jay Levin",
            title: "CEO Equip Our Kids LA Weekly Magazine"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/bQ694TD-QCE",
            name: "Giovanni Villa",
            title: "CEO Bella2Bello"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/KLLaclzl5r0",
            name: "Paul Claxton",
            title: "Managing General Partner"
        }

    ];
    const testimonialCount = videotestimonialData.length;
    /* video testimonial */
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 3 + testimonialCount) % testimonialCount);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonialCount);

    };

    const getDisplayedTestimonials = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(videotestimonialData[(currentIndex + i) % testimonialCount]);
        }
        return displayedTestimonials;
    };

    const handlePrevMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleNextMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex === videotestimonialData.length - 1 ? 0 : (isMobile ? prevIndex + 1 : 0)));
    };

    /*  */



    /* 1366x768 resolution for the section(what if you had 1000 AI agents.) */
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth <= 1366 && window.innerHeight <= 768);
            setIsIpadMini(window.innerWidth <= 1024 && window.innerHeight <= 768);
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*  */

    /* Text testimonial */
    const testimonialtoshow = (isMobile) ? 1 : 3;

    var testimonialsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: testimonialtoshow,
        slidesToScroll: 1,
        arrows: true,
    };
    const testimonials = [
        {
            name: "CRAIG SHAH/CEO",
            icon: <img src={testimonialImg4} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Amazing!",
            reference: "EVOKE"
        },
        {
            name: "KURT KUMAR/CEO",
            icon: <img src={testimonialImg3} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Traffic Monster solves a problem that every business encounters.  How do you get traffic and leads and ultimately sales to your business.",
            reference: "SAPX"
        },
        {
            name: "JAMAL WOODLEY/CEO",
            icon: <img src={testimonialImg1} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "It's very impressive.  I think this is something that every entrepreneur will be excited about..",
            reference: "MINDMADE BRANDS"
        }
    ];
    const testimonialCount1 = testimonials.length;

    const handlePrevTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex - 1 + testimonialCount1) % testimonialCount1);
    };

    const handleNextTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex + 1) % testimonialCount1);
    };

    const getDisplayedTestimonials1 = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(testimonials[(currentIndexTextTesti + i) % testimonialCount1]);
        }
        return displayedTestimonials;
    };
    /*  */

    useEffect(() => {
        // Calculate random integer when totalPages is available
        if (totalPages !== null) {
            const min = 1;
            const max = totalPages;
            const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
            setRandomInteger(randomInteger);
        }
    }, [totalPages]);

    /* News portion Blogs */
    const supportEmail: string = process.env.REACT_APP_SUPPORT_EMAIL || '';
    const getBlogs = useGetgeneratedBlogListingsQuery({ promptid: ''/* promptid */, userid: ''/* supportEmail */, limitval: 3, skipval: randomInteger });

    useEffect(() => {
        if (getBlogs.data) {
            setBlogs([...getBlogs.data]);
        }
    }, [getBlogs]);
    /*  */

    /* random customer blogs */
    const getRandomBlogs = useGetrandomBlogListingsQuery({});

    useEffect(() => {
        if (getRandomBlogs.data) {
            setRandomBlogs([...getRandomBlogs.data]);
        }
    }, [getRandomBlogs]);
    /*  */

    // const domain = window.location.hostname;
    const handleReadMoreClick = (userid: any) => {
        window.open(`/blogs?userid=${userid}`, '_blank');
    }

    const supportUserid = process.env.REACT_APP_SUPPORT_USER_ID || '';

    const handleUnload = () => {
        localStorage.removeItem('CreatingfromLanding');
        localStorage.removeItem('UrlfromLanding');
        localStorage.removeItem('user_pass');
        localStorage.removeItem('user_name');
        // localStorage.removeItem('user_email');
    };
    window.addEventListener("beforeunload", handleUnload);

    useEffect(() => {
        localStorage.removeItem('CreatingfromLanding');
        localStorage.removeItem('UrlfromLanding');
        localStorage.removeItem('user_pass');
        localStorage.removeItem('user_name');
        // localStorage.removeItem('user_email');
    }, []);

    const clearCookies = () => {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [name] = cookie.split("=");
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
    };

    const clearLocalStorage = () => {
        const keysToRemove = ["msg", "complexity", "module", "subscription_level", "free_plan", "localStorage_msg", "allSet", "Blogs Generated", "remaining_wish_count", "userRole", "payment", "user_email", "logged_in", "prevImg", "fromUpgrade", "blogsCount", "Generating Count", "finalData", "user_pass", "fromNewcampaign", "campaignid", "returningtocampaign", "sourceurl", "campaignName", "paymentOption", "autoPublish", "limitPublish", "campId", "smUrlpublish", "addTags", "captureLeads", "newsLetter", "twitterBtn", "fbBtn", "pintrestBtn", "linkedinBtn", "video", "twitterurl", "fburl", "pintresturl", "linkedinurl", "owndomain", "ChangepayMode", "IdChangingMode", "user_name", "refreshCount", "publishingCampId", "Authorurl", "Authorname", "authorDefaultCheck", "appsumo_coupon_code", "podcastUrl", "AlertMessage", "CreatingfromLanding", "UrlfromLanding", "signedup", "uploadedvideo", "uploadedVideoName", "uploadStatus"];
        keysToRemove.forEach(key => localStorage.removeItem(key));
    };

    const handleGenerateBlogClick = async (url: string, userid: string, uploadedtext: string, youtubeUrl: any) => {
        clearCookies();
        localStorage.clear();
        if (url) {
            localStorage.clear();

            /* let youtubeMatch = url.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?/
            ); */

            // if (youtubeMatch) {
            localStorage.setItem('CreatingfromLanding', 'true');
            localStorage.setItem('UrlfromLanding', url);
            if (userid === '') {
                navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), UrlfromLanding: localStorage.getItem('UrlfromLanding') } });
            }
            /* } else if (!youtubeMatch) {
                toast.error('Please enter valid youtube video url!');
            } */
        } else if (youtubeUrl) {
            const youtubeMatch = youtubeUrl?.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/|live\/|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]+)/

            );

            if (youtubeMatch) {
                localStorage.setItem('CreatingfromLanding', 'true');
                localStorage.setItem('YoutubeUrlfromLanding', youtubeUrl);
                if (userid === '') {
                    navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), UrlfromLanding: localStorage.getItem('UrlfromLanding'), YoutubeUrlfromLanding: localStorage.getItem('YoutubeUrlfromLanding') } });
                }
            } else if (!youtubeMatch) {
                toast.error('Somethign went wrong, check your url format.');
            }
        }
        else if (/* localStorage.getItem('uploadStatus') == 'success' && */ userBusinessText) {
            localStorage.setItem('CreatingfromLanding', 'true');
            localStorage.setItem('uploadedText', userBusinessText);
            if (userid === '') {
                navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), TextfromLanding: localStorage.getItem('uploadedText')/*  VideofromLanding: localStorage.getItem('uploadedvideo') */ } });
            }
        } else if (!url || !uploadedtext) {
            toast.error('Please enter your business url/upload text!');
        }
    }

    const handlevideoChange = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setUploadVideo(file.name);
            console.log('Uploaded video file:', file);
            const formData = new FormData();
            formData.append('media_file', file);

            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            setIsLoading(true);
            try {
                const response = await axios.post(
                    `${apiUrl}/api/prompts/upload_media_file`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response.data.data);
                if (response.data.result === 'success' && response.data.data) {
                    localStorage.setItem('uploadedVideoName', file.name);
                    localStorage.setItem('uploadStatus', 'success');
                    setUploadedvideopath(response.data.data);
                    toast.success('File Uploaded Successfully!');

                    localStorage.setItem('CreatingfromLanding', 'true');
                    localStorage.setItem('uploadedvideo', response.data.data);
                    // if (localStorage.getItem('user_email') === '') {
                    navigate('/signup', {
                        state: {
                            CreatingfromLanding: localStorage.getItem('CreatingfromLanding'),
                            VideofromLanding: localStorage.getItem('uploadedvideo'),
                            videoNamefromLanding: localStorage.getItem('uploadedVideoName')
                        }
                    });
                    // }
                } else if (response.data.result === 'fail') {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error uploading video:', error);
                toast.error('Error uploading video');
            } finally {
                setIsLoading(false);
            }

        }
    };

    const handleTextChange = (event: any) => {
        let inputText = event.target.value;
        setUserBusinessText(inputText);
        localStorage.setItem('CreatingfromLanding', 'true');
        localStorage.setItem('uploadedText', inputText);
    };

    const handlescrapPage = async (url: string) => {
        setIsScrapcheckloading(true);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.get(
                `${apiUrl}/api/prompts/scrap_page?url=${url}`,
            );
            if (response.data.result === 'failed') {
                toast.error(response.data.data);
            } else {
                handleGenerateBlogClick(url, localStorage.getItem('user_email') || '', userBusinessText, youtubeUrl);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsScrapcheckloading(false);
        }
    }

    const handleUrlPrefixChange = (event: any) => {
        const selectedValue = event.target.value;
        setUrlPrefix(selectedValue);
        console.log('URL Prefix Changed:', selectedValue);
    }

    useEffect(() => {
        console.log('urlPrefix updated:', urlPrefix);
    }, [urlPrefix]);

    const handleGetStartedClick = async () => {
        if (!contactname && !contactemail && !contactPhno && !contactMessage) {
            toast.error('Please fill your details!');
        } else {
            const formData = new FormData();
            formData.append('name', contactname);
            formData.append('email', contactemail);
            formData.append('phone', contactPhno);
            formData.append('subject', contactMessage);
            formData.append('message', contactMessage);
            try {
                setIsContacting(true);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(apiUrl + '/api/auth/contactus', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.data.status === 'success') {
                    toast.success(response.data.message);
                    setContactName('');
                    setContactEmail('');
                    setContactPhNo('');
                    setContactMessage('');
                } else {
                    toast.error('Please try agin later!');
                }
            } catch (error) {
                console.log('Error while contacting', error);
            } finally {
                setIsContacting(false);
            }
        }
    }

    return (
        <>
            <div style={{ background: '#000' }}>


                {/* Header */}
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-md">
                            <div className="container-fluid">
                                <a className="navbar-brand" href="/">
                                    {/* <img src="./img/logo.png" alt="" /> */}
                                    <img src={ocesha_logo} className="img-fluid" alt="" />

                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarScroll"
                                    aria-controls="navbarScroll"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="">
                                        <i className="bi bi-list text-white" />
                                    </span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarScroll">
                                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link nav_active"
                                                style={{ color: "#4ba5eb", fontWeight: 800 }}
                                                href="/"
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            navigate("/podcast");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Podcast
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            navigate("/agency");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Agency
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            navigate("/small-business");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Small Business
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            localStorage.setItem('pricing_from_landing', 'true');
                                            navigate("/pricing");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                                Pricing
                                            </a>
                                        </li>
                                        {/*  <li className="nav-item" style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup?revgems-session-id=45a7e1ae-a67a-4197-9973-f002c2338e37&_gl=1*159ev21*_gcl_au*MjI5MjQ3ODg2LjE3MTQ3MzU2NTE.*_ga*MTAyOTUzNzQyMC4xNzE0NzM1NjU0*_ga_YJYFH7ZS27*MTcxNTY3NjkxOC40LjEuMTcxNTY3NzIwMy41MS4wLjA">
                                            Affiliates
                                        </a>
                                    </li> */}
                                        {/* <li className="nav-item" style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                                                Affiliates
                                            </a>
                                        </li> */}

                                        <li className="nav-item" onClick={() => {
                                            navigate("/contactus");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                                Contact
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            window.open(`/blogs`, '_blank');
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Blogs
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="d-flex gap-4" role="search" style={{ justifyContent: isMobile ? 'center' : '' }}>
                                        <button className="btn btn-outline-primary px-3"
                                            onClick={() => {
                                                if (localStorage.getItem('logged_in') === 'true') {
                                                    navigate('/codegenie/generated-blogs', { state: { userEmail: localStorage.getItem('user_email') || '' } });
                                                } else {
                                                    navigate("/signin");
                                                }
                                            }}>Sign  In</button>
                                        <button className="button_leniar px-3" type="submit" onClick={() => {
                                            navigate("/signup", { state: { DirectSignup: 'true' } });
                                        }}>
                                            {/* Try Now */}Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <hr className="mt-3" style={{ color: "#9aa0a3" }} />
                    </div>
                </div>
                {/*  */}

                <div className="container">

                    {/* Podcast Monster Section */}
                    <div
                        ref={(el) => sections.current[0] = el}
                        className="section podcast-monster-section"
                        style={{
                            // backgroundImage: `url(${podcastImg})`,
                            backgroundImage: `url(${headerbg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            backgroundBlendMode: 'overlay',
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >

                        <div className="row  mb-5">
                            <div className="col-12 text-white text-center" style={{ padding: '20px' }}>
                                <h2
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '46px',
                                        lineHeight: isMobile ? '1.4' : '1.6'
                                    }}
                                >
                                    OCESHA
                                </h2>
                                <h2
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '33px',
                                        lineHeight: isMobile ? '1.4' : '1.6'
                                    }}
                                >
                                    Transform Your Podcast Episodes Into Engaging<br />Blogs, Grow Your Audience, and Monetize Your<br />Content Effortlessly
                                </h2>
                            </div>
                            <div className="row" style={{ justifyContent: isMobile ? 'center' : '', paddingLeft: isMobile ? '7%' : '' }}>
                                <div className="col-sm-12 col-md-1 col-lg-1 d-none">
                                    &nbsp;
                                </div>
                                <div style={{ background: '#0E1015', borderRadius: '12px', padding: '1%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={2} sx={{
                                            color: '#344054', display: urlPrefix === 'text' ? '' : 'flex',
                                            alignItems: urlPrefix === 'text' ? '' : 'center'
                                        }}>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    padding: 1,
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                            >
                                                <Select
                                                    value={urlPrefix}
                                                    onChange={handleUrlPrefixChange}
                                                    // open={false}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    sx={{
                                                        width: '100%',
                                                        height: '35px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%'
                                                        },
                                                        backgroundColor: 'white',
                                                        color: '#2A313A',
                                                        '& .MuiSelect-icon': {
                                                            color: '#2A313A',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="https://">Website URL</MenuItem>
                                                    {/* <MenuItem value="text">Text</MenuItem> */}
                                                    <MenuItem value="youtubeid">Youtube Url</MenuItem>
                                                    {/* <MenuItem value="upload">Upload Video/Audio File</MenuItem> */}
                                                </Select>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} sm={10}>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    padding: 1,
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    urlPrefix === 'https://' ?
                                                        <TextField
                                                            placeholder="Enter your website url"
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                width: '100%',
                                                                height: '35px',
                                                                '.MuiInputBase-root': {
                                                                    height: '100%'
                                                                },
                                                                pointerEvents: 'block'
                                                            }}
                                                            value={businessUrl}
                                                            onChange={(e) => setBusinessUrl(e.target.value)}
                                                        // id="sourceurl"
                                                        ></TextField> :
                                                        <TextField
                                                            placeholder="Enter Your Youtube url"
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                width: '100%',
                                                                height: '35px',
                                                                '.MuiInputBase-root': {
                                                                    height: '100%'
                                                                },
                                                                pointerEvents: 'block'
                                                            }}
                                                            value={youtubeUrl}
                                                            onChange={(e) => setYoutubeUrl(e.target.value)}
                                                            id="sourceurl"
                                                        ></TextField>}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                        <button
                                            className="px-3"
                                            type="submit"
                                            style={{
                                                // display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '22px',
                                                fontWeight: 'bold',
                                                width: isMobile ? '74%' : '20%',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                filter: isLoading ? 'blur(2px)' : 'none',
                                                pointerEvents: isLoading ? 'none' : 'auto',
                                                height: '65px',
                                                background: '#662D91',
                                                color: '#fff',
                                                cursor: "pointer",
                                                border: 'none'
                                            }}
                                            onClick={() => {
                                                if (businessUrl) {
                                                    handlescrapPage(businessUrl);
                                                } else {
                                                    handleGenerateBlogClick(businessUrl ? businessUrl : '', localStorage.getItem('user_email') || '', userBusinessText, youtubeUrl);
                                                }
                                            }}
                                            disabled={isLoading}
                                        >
                                            {isScrapcheckloading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : <>TRY IT FOR FREE</>}
                                        </button>
                                    </div>

                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: '#fff',
                                            width: isMobile ? '40%' : '14%',
                                            gap: '5px',
                                            padding: '10px',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }} onClick={() => window.open('https://www.trustpilot.com/review/ocesha.com', '_blank')}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px',
                                                justifyContent: 'center'
                                            }}>
                                                <img src={TrustPilotLogo} style={{ width: '15%' }} />
                                                <span style={{
                                                    color: '#000',
                                                    fontWeight: 'bold'
                                                }}>
                                                    TrustPilot
                                                </span>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                gap: '2px'
                                            }}>
                                                {[...Array(4)].map((_, index) => (
                                                    <StarIcon
                                                        key={index}
                                                        style={{
                                                            color: '#FFD700',
                                                            fontSize: '15px'
                                                        }}
                                                    />
                                                ))}
                                                <StarHalf style={{
                                                    color: '#FFD700',
                                                    fontSize: '15px'
                                                }} />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                gap: '2px',
                                                color: '#000',
                                                fontSize: '12px'
                                            }}>
                                                4.5/5
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-1 col-lg-1 d-none">
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="mx-sm-0 mx-2" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                            {/* <div className="col-12 rounded-1" style={{ width: '85%', padding: isMobile ? '4%' : '1%' }}>
                                <Typography style={{ textAlign: 'center', width: "100%", marginBottom: isMobile ? '14%' : '' }}>By Rohan Hall</Typography>
                                <div className="gap-3 justify-content-center" style={{ display: "flex", flexWrap: isMobile ? 'nowrap' : 'wrap', flexDirection: isMobile ? 'column' : 'row', width: "100%", justifyContent: "center", alignItems: "center", }}>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={oracle} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={hp} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={honda} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={american_red_cross} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={nv_energy} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={avery_dennison} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={corning} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                </div>
                            </div> */}
                            <Typography style={{ textAlign: 'center', width: "100%", /* marginTop: isMobile ? '14%' : '4%', */ fontSize: '27px', color: '#fff', fontWeight: 'bold' }}>Maximize Your Podcast's Reach with AI-Powered Content<br />Creation</Typography>
                        </div>

                    </div>
                    {/* <hr></hr> */}
                    {/* Podcast Monster Section End*/}

                    {/* How Ocesha works Section */}
                    <div
                        ref={(el) => sections.current[1] = el}
                        className="section key-features-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row">
                            {/* <div className="col-sm-12 col-md-1 col-lg-1"></div> */}
                            <div className="col-sm-12 col-md-7 col-lg-7" style={{ paddingLeft: '3%' }}>
                                <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold', textAlign: 'left' }}>How Ocesha Works</h4>
                                <div className="timeline1" style={{ marginTop: '10%' }}>
                                    <div className="step1">
                                        <div className="circle-container1">
                                            <div className="circle1">1</div>
                                            <div className="line1"></div>
                                        </div>
                                        <div className="text1">
                                            <span style={{ fontSize: '17px', fontWeight: 'bold' }}>Repurpose</span><br />
                                            Turn your podcast episodes into engaging, SEO-rich<br />blogs.</div>
                                    </div>

                                    <div className="step1">
                                        <div className="circle-container1">
                                            <div className="circle1">2</div>
                                            <div className="line1"></div>
                                        </div>
                                        <div className="text1">
                                            <span style={{ fontSize: '17px', fontWeight: 'bold' }}>Optimize</span><br />
                                            Enhance your content with AI-powered SEO tools to<br />improve visibility.</div>
                                    </div>

                                    <div className="step1">
                                        <div className="circle-container1">
                                            <div className="circle1">3</div>
                                            <div className="line1"></div>
                                        </div>
                                        <div className="text1">
                                            <span style={{ fontSize: '17px', fontWeight: 'bold' }}>Promote</span><br />
                                            Use social media posts, images, and blogs to<br />engage your audience.</div>
                                    </div>

                                    <div className="step1">
                                        <div className="circle-container1">
                                            <div className="circle1">4</div>
                                            <div className="line1"></div>
                                        </div>
                                        <div className="text1">
                                            <span style={{ fontSize: '17px', fontWeight: 'bold' }}>Monetize</span><br />
                                            Add sponsorships and affiliate links to blogs for<br />additional revenue.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-start" style={{ alignSelf: 'center', paddingRight: '3%' }}/* style={{ justifyContent: 'flex-end', display: 'flex' }} */>
                                <img src={podcast_ocesha_section2} style={{
                                    width: /* isMobile ?  */'100%' /* : '338px' */,
                                    maxWidth: '100%',
                                    marginTop: isMobile ? '5%' : ''
                                }}></img>
                            </div>
                        </div>
                    </div>
                    {/* How Ocesha works Section End */}

                    {/* Why choose ocesha Section */}
                    <div
                        ref={(el) => sections.current[2] = el}
                        className="section key-features-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ paddingLeft: '3%' }}>
                            <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold', textAlign: 'left' }}>Why Choose OceSha?</h4>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: isMobile ? '' : 'flex', marginTop: '5%' }}>
                                <div className={isMobile ? "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-left" : "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                    <p className={"responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>1</p>
                                </div>
                                <div className="col-sm-12 col-md-10 col-lg-10" style={{ marginTop: isMobile ? '2%' : '' }}>
                                    <h5 className="card-title" style={{ fontSize: '17px', fontWeight: 'bold' }}>Content Repurposing</h5>
                                    <p className="card-text" style={{ fontSize: '17px', marginTop: '5%', textAlign: 'left' }}>
                                        Convert podcasts into SEO-optimized blogs to<br />expand your reach and attract more listeners.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: isMobile ? '' : 'flex', marginTop: '5%' }}>
                                <div className={isMobile ? "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-left" : "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                    <p className={"responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>2</p>
                                </div>
                                <div className="col-sm-12 col-md-10 col-lg-10" style={{ marginTop: isMobile ? '2%' : '' }}>
                                    <h5 className="card-title" style={{ fontSize: '17px', fontWeight: 'bold' }}>SEO Optimization</h5>
                                    <p className="card-text" style={{ fontSize: '17px', marginTop: '5%', textAlign: 'left' }}>
                                        Use AI to embed keywords, internal/external links,<br />and improve rankings on search engines.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ paddingLeft: '3%' }}>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: isMobile ? '' : 'flex', marginTop: '5%' }}>
                                <div className={isMobile ? "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-left" : "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                    <p className={"responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>3</p>
                                </div>
                                <div className="col-sm-12 col-md-10 col-lg-10" style={{ marginTop: isMobile ? '2%' : '' }}>
                                    <h5 className="card-title" style={{ fontSize: '17px', fontWeight: 'bold' }}>All-in-One Content Platform</h5>
                                    <p className="card-text" style={{ fontSize: '17px', marginTop: '5%', textAlign: 'left' }}>
                                        Create blogs, social posts, and AI-designed images<br />to promote your episodes.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: isMobile ? '' : 'flex', marginTop: '5%' }}>
                                <div className={isMobile ? "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-left" : "col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                    <p className={"responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>4</p>
                                </div>
                                <div className="col-sm-12 col-md-10 col-lg-10" style={{ marginTop: isMobile ? '2%' : '' }}>
                                    <h5 className="card-title" style={{ fontSize: '17px', fontWeight: 'bold' }}>Monetization Opportunities</h5>
                                    <p className="card-text" style={{ fontSize: '17px', marginTop: '5%', textAlign: 'left' }}>
                                        Add affiliate links and sponsorships to blogs,<br />turning your podcast into a revenue stream.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Why choose ocesha Section End */}

                    {/* By rohan hall Section */}
                    <div
                        ref={(el) => sections.current[3] = el}
                        className="section key-features-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-12 rounded-1" style={{ width: '85%', padding: isMobile ? '4%' : '1%' }}>
                                <Typography style={{ textAlign: 'center', width: "100%", marginBottom: isMobile ? '14%' : '' }}>By Rohan Hall</Typography>
                                <div className="gap-3 justify-content-center" style={{ display: "flex", flexWrap: isMobile ? 'nowrap' : 'wrap', flexDirection: isMobile ? 'column' : 'row', width: "100%", justifyContent: "center", alignItems: "center", }}>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={oracle} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={hp} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={honda} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={american_red_cross} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={nv_energy} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={avery_dennison} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={corning} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* By rohan hall Section End */}

                    {/* key benefits Section */}
                    <div
                        ref={(el) => sections.current[4] = el}
                        className="section choose-podcastmons-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ paddingLeft: '3%' }}>
                                        <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold', textAlign: 'left' }}>Key Benefits</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 col-lg-3">
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <img src={key_benefits1_podcast} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#fff', textAlign: 'left', marginTop: '6%' }}>Expand Audience</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#fff', textAlign: 'left', marginTop: '5%', fontWeight: 'normal' }}>
                                                    Repurpose podcast{isMobile ? ' ' : <br></br>}content into high-{isMobile ? ' ' : <br></br>}performing blogs to reach{isMobile ? ' ' : <br></br>}new listeners.
                                                </p>
                                                {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3">
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <img src={key_benefits2_podcast} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#fff', textAlign: 'left', marginTop: '6%' }}>Save Time</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#fff', textAlign: 'left', marginTop: '5%', fontWeight: 'normal' }}>
                                                    Get ready-to-publish,{isMobile ? ' ' : <br></br>}professional-quality blogs{isMobile ? ' ' : <br></br>}without extra effort.
                                                </p>
                                                {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3">
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <img src={key_benefits3_podcast} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#fff', textAlign: 'left', marginTop: '6%' }}>Boost Visibility</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#fff', textAlign: 'left', marginTop: '5%', fontWeight: 'normal' }}>
                                                    Improve search rankings{isMobile ? ' ' : <br></br>}with advanced SEO{isMobile ? ' ' : <br></br>}techniques.
                                                </p>
                                                {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3">
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <img src={key_benefits4_podcast} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#fff', textAlign: 'left', marginTop: '6%' }}>Monetize Content</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#fff', textAlign: 'left', marginTop: '5%', fontWeight: 'normal' }}>
                                                    Generate revenue through{isMobile ? ' ' : <br></br>}affiliate links and{isMobile ? ' ' : <br></br>}sponsorships in blogs.
                                                </p>
                                                {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key benefits Section End */}

                    {/* customizable CTA's Section */}
                    <div
                        ref={(el) => sections.current[5] = el}
                        className="section choose-podcastmons-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ paddingLeft: '3%' }}>
                                        <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold', textAlign: 'left' }}>Customizable (Call To Action) CTAs</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#fff', textAlign: 'left', marginTop: '6%' }}>Drive Traffic</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#fff', textAlign: 'left', marginTop: '5%', fontWeight: 'normal' }}>
                                                    Create tailored calls-to-action that direct listeners to<br />your podcast, increasing your audience and<br />engagement.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#fff', textAlign: 'left', marginTop: '6%' }}>Boost Conversions</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#fff', textAlign: 'left', marginTop: '5%', fontWeight: 'normal' }}>
                                                    Strategically place CTAs within your repurposed content<br />to maximize listener action and grow your podcast's<br />impact.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* customizable CTA's Section End */}

                    {/* Multilingual content Section */}
                    <div
                        ref={(el) => sections.current[6] = el}
                        className="section benefit-features-section"
                        style={{
                            backgroundImage: `url(${multilingual_section_podcast})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            backgroundBlendMode: 'overlay',
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    {/* <div className="col-sm-12 col-md-3 col-lg-3"></div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ paddingLeft: '3%' }}>
                                        <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold' }}>Multilingual Content</h4>
                                    </div>
                                    {/* <div className="col-sm-12 col-md-3 col-lg-3"></div> */}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%', paddingLeft: '3%', paddingRight: '3%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left' }}>Global Reach</h5>
                                                <p className="card-text" style={{ fontSize: '15px', fontWeight: 'normal', textAlign: 'left' }}>
                                                    Generate blogs in any language to connect<br />with a worldwide audience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left' }}>Expand Market</h5>
                                                <p className="card-text" style={{ fontSize: '15px', fontWeight: 'normal', textAlign: 'left' }}>
                                                    Tap into new markets by repurposing your<br />podcast content for diverse language speakers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: /* isMobile ? */ '100%'/*  : '105px' */ }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left' }}>Cultural Adaptation</h5>
                                                <p className="card-text" style={{ fontSize: '15px', fontWeight: 'normal', textAlign: 'left' }}>
                                                    AI-powered translation ensures your content<br />resonates acrossdifferent cultures and regions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Multilingual content Section End */}

                    {/* Effortless content management Section */}
                    <div
                        ref={(el) => sections.current[7] = el}
                        className="section key-features-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row">
                            {/* <div className="col-sm-12 col-md-1 col-lg-1"></div> */}
                            <div className="col-sm-12 col-md-7 col-lg-7" style={{ paddingLeft: '3%' }}>
                                <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold', textAlign: 'left' }}>Effortless Content Management</h4>
                                <div className="chevron-container" style={{ marginTop: '9%' }}>
                                    <div className="chevron-item">
                                        <div className="chevron">1</div>
                                        <div className="content">
                                            <p style={{ fontSize: '17px', fontWeight: 'bold' }}>Upload Podcast</p>
                                            <p style={{ textAlign: 'left' }}>Simply upload your podcast episode to the OceSha<br />platform.</p>
                                        </div>
                                    </div>

                                    <div className="chevron-item">
                                        <div className="chevron">2</div>
                                        <div className="content">
                                            <p style={{ fontSize: '17px', fontWeight: 'bold' }}>AI Processing</p>
                                            <p>Our AI technology analyzes and repurposes your<br />content.</p>
                                        </div>
                                    </div>

                                    <div className="chevron-item">
                                        <div className="chevron">3</div>
                                        <div className="content">
                                            <p style={{ fontSize: '17px', fontWeight: 'bold' }}>Review and Edit</p>
                                            <p>Make any final adjustments to your AI-generated<br />content.</p>
                                        </div>
                                    </div>

                                    <div className="chevron-item">
                                        <div className="chevron">4</div>
                                        <div className="content">
                                            <p style={{ fontSize: '17px', fontWeight: 'bold' }}>Publish and Share</p>
                                            <p>Distribute your repurposed content across multiple<br />platforms with a single click.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-start" style={{ alignSelf: 'center', paddingRight: '3%' }}/* style={{ justifyContent: 'flex-end', display: 'flex' }} */>
                                <img src={content_management_section_podcast} style={{
                                    width: /* isMobile ?  */'100%' /* : '338px' */,
                                    maxWidth: '100%',
                                    marginTop: isMobile ? '5%' : ''
                                }}></img>
                            </div>
                        </div>
                    </div>
                    {/* Effortless content management Section End */}

                    {/* take your podcast to new heights Section */}
                    <div
                        ref={(el) => sections.current[8] = el}
                        className="section choose-podcastmons-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>
                            <div className="col-sm-12 col-md-12 col-lg-12" /* style={{ marginTop: '2%' }} */>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <img src={last_section_podcast} style={{
                                            width: /* isMobile ?  */'100%' /* : '338px' */,
                                            maxWidth: '100%',
                                            marginTop: isMobile ? '5%' : ''
                                        }}></img>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold', textAlign: 'left' }}>Take Your Podcast to New<br />Heights!</h4>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#fff', textAlign: 'left', marginTop: '10%', fontWeight: 'normal' }}>
                                                    Try OceSha for free and discover how AI can help you grow your podcast audience and revenue. Start transforming your podcast content into engaging blogs and social media posts today.
                                                </p>
                                                <Button sx={{
                                                    background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '1%', width: isMobile ? '100%' : '35%',
                                                    '&:hover': {
                                                        background: '#F65F62',
                                                        color: '#000',
                                                    },
                                                    height: '50px'
                                                }} onClick={() => sections.current[0]?.scrollIntoView({ behavior: 'smooth' })}>Start Your Free Trial</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* take your podcast to new heights Section End */}

                    {/* Testimonials Section */}
                    <div
                        ref={(el) => sections.current[9] = el}
                        className="section benefit-features-section"
                        style={{
                            // backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            // backgroundColor: '#eeb0b1', // Overlay color with reduced opacity
                            backgroundColor: '#B9474A',
                            // backgroundBlendMode: 'overlay', // Blends the image with the overlay color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    {/* <div className="col-sm-12 col-md-3 col-lg-3"></div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ paddingLeft: '3%' }}>
                                        <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold' }}>Testimonials</h4>
                                    </div>
                                    {/* <div className="col-sm-12 col-md-3 col-lg-3"></div> */}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%', paddingLeft: '3%', paddingRight: '3%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', display: 'flex', alignItems: 'center' }}><PersonIcon sx={{ fontSize: '35px' }} />Thomas White</h5>
                                                <p className="card-text" style={{ fontSize: '15px' }}>
                                                    Our social media engagement rates have skyrocketed since we started using Ocesha. The interactive content and thoughtful personalization make our posts stand out.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', display: 'flex', alignItems: 'center' }}><PersonIcon sx={{ fontSize: '35px' }} />Jarvis</h5>
                                                <p className="card-text" style={{ fontSize: '15px' }}>
                                                    The AI content generation is incredibly intuitive and produces blog posts that feel human-written. My blog views have doubled since using Ocesha.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: /* isMobile ? */ '100%'/*  : '105px' */ }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', display: 'flex', alignItems: 'center' }}><PersonIcon sx={{ fontSize: '35px' }} />Hendrix Patterson</h5>
                                                <p className="card-text" style={{ fontSize: '15px' }}>
                                                    OceSha has taken my small business to the next level. The automated content distribution alone has saved me countless hours. I’m seeing better engagement and sales thanks to this platform.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Testimonials Section End */}

                    {/* FAQ Section */}
                    <div
                        ref={(el) => sections.current[10] = el}
                        className="section faq-questions-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginTop: '5%' }}>
                            <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold' }}>OceSha FAQ</h4>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ textAlign: 'center'/* , marginTop: '5%' */ }}>
                                <div style={{ marginTop: '3%' }}>
                                    <p style={{ color: '#fff', fontSize: '40px' }}>Do you have<br />
                                        any questions?</p>
                                    <p style={{ color: '#fff', fontSize: '16px' }}>Feel free to send us your questions or request a free consultation.</p>
                                </div>

                                <div className="row" style={{ display: 'flex', gap: '12px', /* marginTop: '4%', */ flexDirection: 'column' }}>
                                    <div className="col-12" style={{ display: 'flex', gap: '4px' }}>
                                        <div className="col-6">
                                            <TextField placeholder="Enter Your Full Name" sx={{
                                                height: '45px',
                                                width: '100%',
                                                '.MuiInputBase-root': {
                                                    height: '100%'
                                                }
                                            }} value={contactname} onChange={(e) => setContactName(e.target.value)}></TextField>
                                        </div>
                                        <div className="col-6">
                                            <TextField placeholder="Enter Your Email" sx={{
                                                height: '45px',
                                                width: '100%',
                                                '.MuiInputBase-root': {
                                                    height: '100%'
                                                }
                                            }} value={contactemail} onChange={(e) => setContactEmail(e.target.value)}></TextField>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ display: 'flex', gap: '4px' }}>
                                        <div className="col-6">
                                            <TextField placeholder="Enter Your PhoneNumber" sx={{
                                                height: '45px',
                                                width: '100%',
                                                '.MuiInputBase-root': {
                                                    height: '100%'
                                                }
                                            }} value={contactPhno} onChange={(e) => setContactPhNo(e.target.value)}></TextField>
                                        </div>
                                        <div className="col-6">
                                            <TextField placeholder="Let us know about your query..." sx={{
                                                height: '45px',
                                                width: '100%',
                                                '.MuiInputBase-root': {
                                                    height: '100%'
                                                }
                                            }} value={contactMessage} onChange={(e) => setContactMessage(e.target.value)}></TextField>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Button sx={{
                                            background: '#F65F62', color: '#000', fontSize: '14px', fontWeight: 'bold', height: '45px', width: '100%',
                                            '&:hover': {
                                                background: '#F65F62',
                                                color: '#000',
                                            }
                                        }} onClick={handleGetStartedClick}>{isContacting ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : "Get Started"}</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ justifyContent: 'center', flexDirection: 'column', width: isMobile ? '100%' : '', alignItems: 'center', marginTop: isMobile ? '10%' : '' }}>

                                <div className="accordion" id="regularAccordionRobots">
                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeadingFirst" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapseFirst"
                                                aria-expanded="true"
                                                aria-controls="regularCollapseFirst"
                                                style={{ color: '#fff' }}
                                            >
                                                What is OceSha?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapseFirst"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="regularHeadingFirst"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                OceSha is an AI-powered platform that helps podcasters expand their reach by repurposing podcast content into SEO-optimized blogs and engaging social media posts.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading2" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse2"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse2"
                                                style={{ color: '#fff' }}
                                            >
                                                How does OceSha repurpose my podcast content?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse2"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeadingFirst"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                OceSha converts your podcast episodes into high-quality blogs that are optimized for search engines and audience engagement.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading3" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse3"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse3"
                                                style={{ color: '#fff' }}
                                            >
                                                Can OceSha help me monetize my podcast?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse3"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading3"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes! OceSha allows you to add affiliate links, sponsorships, and interactive CTAs to blogs, enabling new revenue opportunities.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading4" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse4"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse4"
                                                style={{ color: '#fff' }}
                                            >
                                                Does OceSha support multiple languages for blog creation?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse4"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading4"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes, OceSha generates blogs in multiple languages, allowing you to connect with a global audience.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading5" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse5"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse5"
                                                style={{ color: '#fff' }}
                                            >
                                                Is OceSha easy to use for non-technical podcasters?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse5"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading5"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Absolutely! OceSha is designed to be user-friendly and intuitive, making it accessible to podcasters with any level of technical expertise.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ's End */}

                </div >
            </div>
        </>
    )
}

export default OceshaPodcastLandingNew;