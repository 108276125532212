import { useState, useContext, useEffect, FormEvent } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  SvgIcon,
  FormControlLabel,
  Switch,
  Divider,
  Stack,
  Modal,
  Paper,
  TextField,
  CircularProgress,
  Tooltip
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";


import { ReactComponent as CrownIcon } from "../../assets/ico_crown.svg";
import { ReactComponent as ProductIcon } from "../../assets/ico_product.svg";
import { ReactComponent as DownIcon } from "../../assets/ico_downarr.svg";
import { ReactComponent as Logo } from '../../assets/logo.svg';

import { ColorModeContext } from '../../theme';
import { useLazyGetModulesQuery, useLazyGetProductQuery, usePlanDetailsMutation } from "../../redux/api/productApi";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { logout } from "../../redux/features/userSlice";
import { useLazyCreateCheckoutSessionQuery, useActivateSubscriptionQuery } from "../../redux/api/subscriptionApi";
import PayNowButton from "../../pages/payment/payNowButton";
import "../../pages/custom_style.css";
import { IPlanDetail } from "../../redux/api/types";
import { useLazyRemainingWishCountQuery, useRemainingWishCountQuery } from "../../redux/api/genieApi";
import logo from "../../assets/newimg/logo.png";
import Logo1 from '../../assets/logo.svg';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useGetUserDetailsMutation } from '../../redux/api/authApi';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useGeneratedbusinessurlblogsMutation } from '../../redux/api/promptApi';
import { useLocation } from "react-router-dom";
import youtubeIco1 from '../../assets/traffic_monster/youtubeIco_1.png';
import InfoIcon from '@mui/icons-material/Info';
import { removeCookie } from "utils/storageUtils";
import OceshaLogo from '../../assets/ocesha_images/ocesha_logo.png';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  marginLeft: 10,
  border: "1px solid #1D252D",
  borderRadius: 8,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    background: theme.palette.mode === "dark" ? "white" : "#1D252D",
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "linear-gradient(#0168B5, #4BA5EB)"
        : "rgba(255,255,255,1)",
    boxSizing: "border-box",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette
    // .mode === "dark" ? "#1A2027" : "#fff",
    .mode === "dark" ? (localStorage.getItem('userRole') === 'user' ? "#1A2027" : "#F9FBFC") : (localStorage.getItem('userRole') === 'user' ? "#1A2027" : "#F9FBFC"),

  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
//console.log('Theme=>' + Item);


function Header() {


  const location = useLocation();
  const urlPath = window.location.pathname;
  const isDashboard = urlPath.includes('/dashboard');
  const isBlogListing = urlPath.includes('/generated-blogs');

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const colorMode = useContext(ColorModeContext);

  const [modules, setModules] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openSetting, setOpenSetting] = useState(false);
  const [openModal, setOpenModal] = useState("Password");



  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const genieSelector = useAppSelector((state) => state.genieState);
  const user = useAppSelector((state) => state.userState.user);

  if (user && user.role) {
    const { role } = user;
    localStorage.setItem('userRole', role);
  }

  const userRole = localStorage.getItem('userRole');

  // Check if the user is logged in
  const isAuthenticated = localStorage.getItem("logged_in");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();
  const [getModules, getState] = useLazyGetModulesQuery();
  const smart_contracte_complexity = localStorage.getItem("complexity");
  const [openPaidModal, setOpenPriceModal] = useState(false);
  const [remainingWishCount, remWishState] = useLazyRemainingWishCountQuery();
  const remainingWishCountQuery = useRemainingWishCountQuery({ "email": user?.email || "" })
  const [openPaidModal1, setOpenPaidModal] = useState(false);
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [openActivateConfirmation, setActivateConfirmation] = useState(false);
  const [period, getPeriodState] = useState("monthly");
  const [getProduct, getProductState] = useLazyGetProductQuery();
  const [freePlan, setFreePlan] = useState(localStorage.getItem('free_plan'));
  const [subscriptionLevel, setSubscriptionLevel] = useState(localStorage.getItem('subscription_level'));
  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
      save: ''
    },
  ]);
  const [wishRemCount, setWishRemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [getUserDetailsMutation] = useGetUserDetailsMutation();
  const [userDetails, setUserDetails] = useState<any>();
  const [planDetails, setPlanDetails] = useState<any>();
  const [generatedBlogDetail, setGeneratedBlogDetail] = useState<any>();
  const [generatedBusinessUrlBlogsMutation] = useGeneratedbusinessurlblogsMutation();
  const [getuserPlanDetails] = usePlanDetailsMutation();
  const [openActivateConfirmationModal, setOpenActivateConfirmationModal] = useState<boolean>(false);
  const [subscription_level, setSubscriptionlevel] = useState<any>();
  const [helpervideosrc, setHelperVideoSrc] = useState<any>();
  const [helperPopup, setHelperPopup] = useState<boolean>(false);
  const [upgradeModal, setUpgradeModal] = useState<boolean>(false);


  useEffect(() => {
    getModules("CodeGenie");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (remainingWishCountQuery.isSuccess)
      setWishRemCount(remainingWishCountQuery.data?.remaining_wish_count);
  }, [remainingWishCountQuery]);

  useEffect(() => {
    if (remWishState.isSuccess) {
      //console.log(remWishState.data?.remaining_wish_count);
      setWishRemCount(remWishState.data?.remaining_wish_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remWishState]);

  useEffect(() => {
    if (checkoutState.isSuccess) {
      window.location = checkoutState.data?.checkout_url;
      //window.open(checkoutState.data?.checkout_url, '_blank');
    }
  }, [checkoutState, navigate]);

  useEffect(() => {

    const { data } = getProductState;
    if (data?.plan_details) {
      //console.log(data?.plan_details);
      setPlans([...data?.plan_details]);
    }
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
          save: ''
        },
      ]);
  }, [getProductState]);

  useEffect(() => {
    if (getState.isSuccess)
      setModules(getState.data.filter((item) => item !== ""));
  }, [getState]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    }
  };

  const handleLogout = () => {
    clearCookies();
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    var module = localStorage.getItem("module");
    console.log("module111...." + module);

    toast.success("Logout success");
    // localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    // localStorage.removeItem("userRole");
    localStorage.removeItem("localStorage_msg");
    localStorage.removeItem("allSet");
    localStorage.removeItem("Blogs Generated");
    localStorage.removeItem("remaining_wish_count");
    localStorage.removeItem("userRole");
    localStorage.removeItem("payment");
    localStorage.removeItem("user_email");
    localStorage.removeItem("logged_in");
    localStorage.removeItem("prevImg");
    localStorage.removeItem("fromUpgrade");
    localStorage.removeItem("blogsCount");
    localStorage.removeItem("Generating Count");
    localStorage.removeItem("finalData");
    localStorage.removeItem("user_pass");
    localStorage.removeItem("fromNewcampaign");
    localStorage.removeItem("campaignid");
    localStorage.removeItem("returningtocampaign");
    localStorage.removeItem('sourceurl');
    localStorage.removeItem('campaignName');
    localStorage.removeItem('paymentOption');
    localStorage.removeItem('autoPublish');
    localStorage.removeItem('limitPublish');
    localStorage.removeItem('campId');
    localStorage.removeItem('campaignid');
    localStorage.removeItem('returningtocampaign');
    localStorage.removeItem('sourceurl');
    localStorage.removeItem('campaignName');
    localStorage.removeItem('paymentOption');
    localStorage.removeItem('autoPublish');
    localStorage.removeItem('limitPublish');
    localStorage.removeItem('smUrlpublish');
    localStorage.removeItem('addTags');
    localStorage.removeItem('captureLeads');
    localStorage.removeItem('newsLetter');
    localStorage.removeItem('twitterBtn');
    localStorage.removeItem('fbBtn');
    localStorage.removeItem('pintrestBtn');
    localStorage.removeItem('linkedinBtn');
    localStorage.removeItem('video');
    localStorage.removeItem('twitterurl');
    localStorage.removeItem('fburl');
    localStorage.removeItem('pintresturl');
    localStorage.removeItem('linkedinurl');
    localStorage.removeItem('owndomain');
    localStorage.removeItem('ChangepayMode');
    localStorage.removeItem('IdChangingMode');
    localStorage.removeItem('user_name');
    localStorage.removeItem('refreshCount');
    localStorage.removeItem('publishingCampId');
    localStorage.removeItem('Authorurl');
    localStorage.removeItem('Authorname');
    localStorage.removeItem('authorDefaultCheck');
    localStorage.removeItem('appsumo_coupon_code');
    localStorage.removeItem('podcastUrl');
    localStorage.removeItem('AlertMessage');
    localStorage.removeItem('CreatingfromLanding');
    localStorage.removeItem('UrlfromLanding');
    localStorage.removeItem('signedup');
    localStorage.removeItem('uploadedvideo');
    localStorage.removeItem('uploadedVideoName');
    localStorage.removeItem('uploadStatus');
    localStorage.removeItem('editedposthashtags');
    localStorage.removeItem('editedpostblogsummary');
    localStorage.removeItem('editedpostblogtitle');
    localStorage.removeItem('editedposturl');
    localStorage.removeItem('user_email');
    localStorage.removeItem('YoutubeUrlfromLanding');
    localStorage.removeItem('FirstLogin');
    localStorage.removeItem('DirectSignup');
    localStorage.removeItem('FromIndexPage');
    localStorage.removeItem('activeItem');
    localStorage.removeItem('FromEditBlog');
    localStorage.removeItem('ErrorAlert');
    removeCookie('user_email');
    localStorage.clear();

    if (module == process.env.REACT_APP_MAIN_PRODUCT_NAME)
      navigate("/Anycode_landing");
    else
      navigate("/");
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();
    //validate before submit for same subscription level

    if (localStorage.getItem("subscription_level") == subscription_level) {
      var cn = window.confirm("You are trying to udgrade the same plan, that you have already registered. Do you want to continue?");
      if (!cn) return false;
    }

    if (subscription_level === "Free") {
      if (user?.email) {
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/signin");
      }
    } else {
      if (isAuthenticated != "true") {
        navigate('/signin');
      } else {
        //createCheckoutSession({ "emailid": user?.email || "", "username": user?.name || "", "subscription_level": subscription_level, "couponcode":"" });
      }

    }
  }


  const handleSetting = () => {
    navigate('/codegenie/settings');
  }

  const handledashboardClick = () => {
    // if(localStorage.getItem('logged_in') === 'true'){
    if (subscriptionLevel === '') {
      setOpenPaidModal(true);
    } else {
      navigate('/codegenie/dashboard');
    }
    // }
  }

  const handleBlogListingClick = () => {
    if (subscription_level === '') {
      setOpenPaidModal(true);
    } else {
      localStorage.setItem('activeItem', 'blogs');
      navigate('/codegenie/generated-blogs');
    }
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setFreePlan(localStorage.getItem('free_plan'));
      setSubscriptionLevel(localStorage.getItem('subscription_level'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const userEmail = localStorage.getItem('user_email') || '';

  useEffect(() => {
    localStorage.setItem('user_email', userEmail);
  }, [userEmail]);
  const fetchData = async () => {
    try {
      const response = await getUserDetailsMutation({ email: userEmail });

      if ('data' in response) {
        // const datas = response.data.data;
        setUserDetails(response.data.data);
        console.log('userDet=>', response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const campId = localStorage.getItem('campId') || '';

  const fetchPlanDatas = async () => {
    try {
      const response = await getuserPlanDetails({ email: userEmail, campaignid: ''/* campId */ });

      if ('data' in response) {
        setPlanDetails(response.data.data);
        console.log('plan details=>', response.data.data);
        setSubscriptionlevel(planDetails.subscription_level);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (campId) {
      fetchPlanDatas();
    }
  }, [campId]);


  const handleActivateSubscription = async () => {
    setIsLoading(true);
    try {
      const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';

      const response = await fetch(apiUrl + `/api/subscription/activate_now/?email=${userEmail}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Subscription activated:', data);
      toast.success(data.message);
      navigate('/activation-success');
    } catch (error) {
      toast.error('Error activating subscription');
      console.error('Error activating subscription:', error);
    } finally {
      setIsLoading(false);
      // setOpenActivationModal(true);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      // if (blogGenerated === 'true') {
      try {
        const response = await generatedBusinessUrlBlogsMutation({ userid: userEmail, createddate: '', createddate_to: '' });
        if ('data' in response) {
          const { data } = response.data;
          setGeneratedBlogDetail(data);
          console.log('data from variable=>', data);
        } else if ('error' in response) {

          const { error } = response;
          console.log('Error:', error);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
      // }
    };

    fetchData();
  }, [generatedBusinessUrlBlogsMutation, userEmail]);

  const url = window.location.pathname + window.location.search;

  /* useEffect(() => {
    const isLoginFirstTime = localStorage.getItem('FirstLogin') === 'True' ? 'True' : 'False';
    if (isLoginFirstTime === 'True' && isDashboard) {
      setHelperVideoSrc("/images/My_Blogs_Page.mp4");
      setHelperPopup(true);
    }
  }, []); */

  return (
    <>
      <Container maxWidth="xl">
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <a className="navbar-brand" href="/"><img src={OceshaLogo} style={{marginLeft: '48%'}}/* style={{ width: '40%' }} */ alt="" /></a>
            {/*  <SvgIcon
              sx={{
                mr: 1,
                width: 44,
                height: 44,
              }}
            >
              <Logo />
            </SvgIcon> */}
            {/* <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "flex" },
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                alignItems: "center",
              }}
            >
              Ocesha
            </Typography> */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
              <span className=""><i className="bi bi-list text-white"></i></span>
            </button>
            {/* {isMobile ?

              <div style={{ padding: '1%' }} >
                <ManageAccountsIcon style={{ fontSize: '150%' }} onClick={handleSetting}></ManageAccountsIcon>
              </div> : null} */}
            <div className="collapse navbar-collapse" id="navbarScroll">
              {!isDashboard ?
                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                  <li className="nav-item">
                    {/* <a className="nav-link text-black active" aria-current="page" href="/">Home</a> */}
                  </li>
                  {/* <li className="mt-1">
                  {(process.env.REACT_APP_SHOW_PRODUCT_MENU == "true") && (
                    <>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        startIcon={
                          <SvgIcon>
                            <ProductIcon />
                          </SvgIcon>
                        }
                        endIcon={
                          <SvgIcon>
                            <DownIcon />
                          </SvgIcon>
                        }
                        sx={{ color: "inherit" }}
                      >
                        Products
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {modules.map((module, index) => (
                          <MenuItem
                            key={`menu_item_${index}`}
                            onClick={() => {
                              handleClose();
                              navigate(
                                `codegenie/${module.toLowerCase().replace(" ", "_")}`
                              );
                              //console.log("module::"+module);
                              localStorage.setItem("module", module);
                              localStorage.removeItem("payment");
                              localStorage.removeItem("msg");
                              localStorage.removeItem("localStorage_msg");
                              localStorage.removeItem("complexity");
                              dispatch(setModule(module));
                            }}
                          >
                            {module}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>)
                  }
                </li> */}
                  {/* <li className="mt-1">
                    <Button sx={{ color: '#CBCBCB', display: generatedBlogDetail > 0 || localStorage.getItem('blogsCount') === 'true' ? 'none' : 'block' }} onClick={handledashboardClick}>
                     Generate
                    </Button>
                  </li> */}

                  {isBlogListing ?
                    <li className="mt-1">
                      <Tooltip title="Click here for help video">
                        <img
                          src={youtubeIco1}
                          onClick={() => {
                            setHelperVideoSrc("/images/My_Blogs_Page.mp4");
                            setHelperPopup(true);
                          }}
                          style={{ width: '60%' }}
                        />
                      </Tooltip>
                    </li> : ''}

                  <li className="mt-1">
                    <Button sx={{ color: '#CBCBCB' }} onClick={handleBlogListingClick}>
                      My Blogs {/* List */}
                    </Button>
                  </li>
                  {/* <li className="mt-1">
                  <Button sx={{ color: '#CBCBCB' }}>
                    <a href="https://trafficmonsterai.getrewardful.com/signup?revgems-session-id=45a7e1ae-a67a-4197-9973-f002c2338e37&_gl=1*159ev21*_gcl_au*MjI5MjQ3ODg2LjE3MTQ3MzU2NTE.*_ga*MTAyOTUzNzQyMC4xNzE0NzM1NjU0*_ga_YJYFH7ZS27*MTcxNTY3NjkxOC40LjEuMTcxNTY3NzIwMy41MS4wLjA" style={{ color: '#CBCBCB', textDecoration: 'none' }}>
                      Affiliates
                    </a>
                  </Button>
                </li> */}
                  {/* latest */}
                  {/*    <li className="mt-1">  
                    <Button sx={{ color: '#CBCBCB' }}>
                      <a href="https://trafficmonsterai.getrewardful.com/signup" style={{ color: '#CBCBCB', textDecoration: 'none' }}>
                        Affiliates
                      </a>
                    </Button>
                  </li> */}
                  {/*  */}

                  <li className="mt-1">
                    {/* {planDetails?.subscription_level === 'level_3' || planDetails?.subscription_level === 'level_6' ?  */}
                    {/* <Button sx={{ color: '#CBCBCB' }} onClick={() => navigate('/codegenie/campaign-list')}>
                      My Campaigns
                    </Button> */}{/* : ''} */}
                  </li>
                  <li>
                    {!isMobile ? (
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", md: "flex" },
                          ml: 12,
                          gap: 6,
                        }}
                      >

                        {/*<Box
                    sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
                  >
                    <Button>Invite Team Members</Button>
                      </Box>*/}

                        <Box className="headerBox"
                          sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
                        >
                          {smart_contracte_complexity && `Complexity: ${smart_contracte_complexity}`}
                        </Box>
                        <Box className="headerBox"
                          sx={{ flexGrow: 1, display: "flex", justifyContent: "left" }}
                        >
                          {smart_contracte_complexity === "Low" ? (
                            <label>Price: $99</label>
                          ) : smart_contracte_complexity === "Medium" ? (
                            <label>Price: $399</label>
                          ) : smart_contracte_complexity === "High" ? (
                            <label>Price: $999</label>
                          ) : smart_contracte_complexity === "Very High" ? (
                            <label>Price: $2,999</label>
                          ) : (
                            <p></p>
                          )}


                        </Box>
                      </Box>
                    ) : null}
                  </li>
                  {/*<li className="nav-item">
                <a className="nav-link disabled text-white">Pricing</a>
                  </li>*/}
                  {isMobile ?
                    <li className="mt-1">
                      <Button
                        sx={{
                          background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                          marginLeft: 0,
                          color: "white",
                          paddingX: 2,
                          paddingY: 1
                        }}
                        onClick={handleLogout}
                      >
                        Logout
                      </Button>
                    </li> : ''}
                </ul>
                : ''}
              <Box sx={{ flexGrow: 0, display: { xs: "block", md: "block" } }}>
                {/* <FormControlLabel
              value="start"
              control={<AntSwitch onChange={colorMode.toggleColorMode} />}
              label="Darkmode"
              labelPlacement="start"
            /> */}


                <Box sx={{ display: "inline-block", paddingRight: 5, paddingX: 2, paddingY: 1 }}>
                  <PayNowButton></PayNowButton>
                </Box>

                {/* <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 0,
                    color: "white",
                    marginRight: '42px',
                    paddingX: 2,
                    paddingY: 1
                  }}
                >
                  Current Plan: {freePlan === '1' ? 'FREE' : (subscriptionLevel === 'level_1' || subscriptionLevel === 'level_4' ? 'BASIC' : subscriptionLevel === 'level_2' || subscriptionLevel === 'level_5' ? 'PRO' : subscriptionLevel === 'level_3' || subscriptionLevel === 'level_6' ? 'TRAFFIC HACKER' : 'FREE')}
                </Button> */}



              </Box>




            </div>

            {/* <div className={isMobile ? 'mt-2' : ''}>
              {
                (parseInt(localStorage.getItem('free_plan') || '0' ) === 0 || parseInt(localStorage.getItem('free_plan') || '1' )  || (wishRemCount <= 0 || localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5")) && (
                  <Button
                    sx={{
                      background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                      marginLeft: isMobile ? 0 : 5,
                      paddingLeft: 5,
                      color: "white",
                      paddingX: 2,
                      paddingY: 1,
                    }}
                    onClick={() => {
                      const value = {
                        _id: "",
                        product_name: "CodeGenie",
                        product_module: genieSelector.module ? genieSelector.module : ""
                      };
                      getProduct(value);
                      navigate('/pricing');
                    }}
                  >
                    {(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5") ? "Upgrade" : "Upgrade"}
                    
                  </Button>
                )
              }

            </div> */}
            {/* {!isMobile ?
              <div style={{ padding: '1%', cursor: 'pointer' }}>
                <AccountCircleOutlinedIcon style={{ fontSize: '150%' }} onClick={handleSetting}></AccountCircleOutlinedIcon>
              </div> : null
            } */}
            {(url !== '/codegenie/new-campaign' && url !== '/codegenie/dashboard') ?
              <Button
                sx={{
                  background: "#CC5500",
                  marginLeft: 0,
                  color: "white",
                  paddingX: 2,
                  paddingY: 1,
                  marginRight: 1
                }}

                onClick={() => {
                  const clearLocalStorage = () => {
                    const keysToRemove = ["campaignid", "returningtocampaign", "sourceurl", "campaignName", "paymentOption",
                      "autoPublish", "limitPublish", "smUrlpublish", "addTags", "captureLeads", "newsLetter", "twitterBtn", "fbBtn", "pintrestBtn", "linkedinBtn",
                      "video", "twitterurl", "fburl", "pintresturl", "linkedinurl", "useOwnDomain", "ChangepayMode", "IdChangingMode", "Authorurl", "Authorname",
                      "authorDefaultCheck", "videoTag", "podcastUrl", "sourcetext"];
                    keysToRemove.forEach(key => localStorage.removeItem(key));
                  };
                  if (planDetails?.subscription_level !== undefined || planDetails !== '' || userDetails.blog_count_balance > 0 || userDetails.podcast_count_balance > 0) {
                    navigate('/codegenie/new-campaign');
                    clearLocalStorage();
                  } else if (userDetails?.blog_count_balance === 0 && userDetails?.podcast_count_balance === 0) {
                    setUpgradeModal(true);
                  } else {
                    localStorage.setItem('fromUpgrade', 'true');
                    navigate('/pricing', { state: { 'campaignIdFromBL': localStorage.getItem('campId'), 'from_page': 'blog_listing', 'useremail': userEmail/* userDets?.email */ } });
                  }
                }}

              >
                Create Blog
              </Button>
              : ''}

            {/*  {planDetails?.mode !== 'Paid' && planDetails?.payment_status !== 'paid' && planDetails?.product_name !== 'Default Campaign' && location.pathname === '/codegenie/generated-blogs' && !userDetails?.appsumo_coupon_code ?
              <Button
                sx={{
                  background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                  marginLeft: 0,
                  color: "white",
                  paddingX: 2,
                  paddingY: 1,
                  marginRight: 1
                }}

                // onClick={handleActivateSubscription}
                onClick={() => setOpenActivateConfirmationModal(true)}

              >
                Activate Plan
              </Button> : ''} */}
            {!isMobile ?
              <Button
                sx={{
                  background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                  marginLeft: 0,
                  color: "white",
                  paddingX: 2,
                  paddingY: 1
                }}
                onClick={handleLogout}
              >
                Logout
              </Button> : ''}
          </div>
        </nav>


      </Container>
      <Divider />

      {isMobile ? (
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "flex" },
            ml: 2,
            gap: 2,
          }}
        >

          {/*<Box
        sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
      >
        <Button>Invite Team Members</Button>
          </Box>*/}

          <Box className="headerBox"
            sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
          >
            {smart_contracte_complexity && `Complexity: ${smart_contracte_complexity}`}
          </Box>
          <Box className="headerBox"
            sx={{ flexGrow: 1, display: "flex", justifyContent: "left" }}
          >
            {smart_contracte_complexity === "Low" ? (
              <label>Price: $99</label>
            ) : smart_contracte_complexity === "Medium" ? (
              <label>Price: $399</label>
            ) : smart_contracte_complexity === "Heigh" ? (
              <label>Price: $999</label>
            ) : smart_contracte_complexity === "Very Heigh" ? (
              <label>Price: $2,999</label>
            ) : (
              <p></p>
            )}


          </Box>
        </Box>
      ) : null}

      <Modal
        open={openPaidModal}
        onClose={() => setOpenPriceModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "98%" : 600,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: "5px",
            // boxShadow: '1px 1px 1px 1px'

          }}
        >
          <h4 id="child-modal-description" style={{ padding: '1%' }}>Pricing Plans</h4>
          <p>Get 2 months free with yearly plan</p>
          <Stack flexDirection="column" sx={{ width: '100%', borderTop: '1px solid' }}>
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Box component="div" textAlign="center" sx={{ p: 2 }}>

                <Button
                  size="large"
                  //variant={period==="monthly"?"contained":"text"}
                  sx={{
                    background: `${period === "monthly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                    color: `${period === "monthly" ? "white" : "inherit"}`,
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    getPeriodState("monthly");
                  }}
                >
                  Monthly billing
                </Button>
                <Button
                  size="large"
                  //variant={period==="yearly"?"contained":"text"}
                  sx={{
                    background: `${period === "yearly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                    color: `${period === "yearly" ? "white" : "inherit"}`,
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    getPeriodState("yearly")
                  }}

                >
                  Yearly billing
                </Button>
              </Box>
              <Stack flexDirection="row" spacing={2} >
                {plans.filter((item) => item.period === period).map((plan, index) => (
                  <>
                    <Item style={{/* margin:"5px" */margin: "1%", width: "100%", padding: "1%" }} key={`plan_item_${index}`}>
                      <Typography sx={{ textAlign: 'center' }}>
                        {plan.total_wishes} {index == 0 ? 'Bug Fixes' : 'Audit Code'}
                      </Typography>
                      <Typography sx={{ textAlign: 'center' }}>
                        ${plan.price}
                      </Typography>

                      <form method="POST" onSubmit={(event) => handleSubmit(event, plan.subscription_level)} style={{ margin: '10%' }}>
                        <Button
                          className="button"
                          size="large"
                          fullWidth
                          type="submit"

                          sx={{
                            background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                            color: "white",
                            paddingX: 2,
                            paddingY: 1,
                          }}
                        >
                          {plan.button_text}
                        </Button>
                      </form>
                    </Item>
                  </>
                ))}
              </Stack>
            </Box>
            <Box component="div" textAlign="center" sx={{ p: 2 }}>
              <Button
                onClick={() => {
                  setOpenPriceModal(false);
                }}
                variant="contained"
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>


      {/* User Setting Popup */}
      <Modal
        open={openSetting}
        onClose={() => setOpenSetting(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "98%" : 600,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: "5px",
            // boxShadow: '1px 1px 1px 1px'

          }}
        >
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
            <h5 id="child-modal-description" style={{ padding: '1%' }}>Settings</h5>
            <Box component="div" textAlign="center" sx={{ padding: '2% 0%' }}>

              <Button
                size="large"
                //variant={period==="monthly"?"contained":"text"}
                sx={{
                  background: `${openModal === "Password" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                  color: `${openModal === "Password" ? "white" : "inherit"}`,
                  paddingX: 2,
                  paddingY: 1,
                }}
                onClick={() => {
                  setOpenModal("Password");
                }}
              >
                Password
              </Button>
              <Button
                size="large"
                //variant={period==="yearly"?"contained":"text"}
                sx={{
                  background: `${openModal === "Products Purchased" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                  color: `${openModal === "Products Purchased" ? "white" : "inherit"}`,
                  paddingX: 2,
                  paddingY: 1,
                }}
                onClick={() => {
                  setOpenModal("Products Purchased")
                }}

              >
                Products Purchased
              </Button>
              <Button
                size="large"
                //variant={period==="yearly"?"contained":"text"}
                sx={{
                  background: `${openModal === "Subscription" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                  color: `${openModal === "Subscription" ? "white" : "inherit"}`,
                  paddingX: 2,
                  paddingY: 1,
                }}
                onClick={() => {
                  setOpenModal("Subscription")
                }}

              >
                Subscription
              </Button>
            </Box>
          </div>
          <Stack flexDirection="column" sx={{ width: '100%', borderTop: '1px solid' }}>
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>

              <Stack flexDirection="row" spacing={2} >
                {openModal == 'Password' ?
                  <div style={{ display: 'grid', padding: '1%', width: '100%' }}>
                    <div style={{ padding: '1%' }}>
                      <Typography sx={{ textAlign: 'Left', fontWeight: 'bold' }}>
                        UserName
                      </Typography>
                      <TextField placeholder="username" style={{ width: '100%' }} />
                    </div>
                    <div style={{ padding: '1%' }}>
                      <Typography sx={{ textAlign: 'Left', fontWeight: 'bold' }}>
                        Password
                      </Typography>
                      <TextField placeholder="Enter current password" style={{ width: '100%' }} />
                    </div>
                    <div style={{ padding: '1%' }}>
                      <Typography sx={{ textAlign: 'Left', fontWeight: 'bold' }}>
                        New Password
                      </Typography>
                      <TextField placeholder="Enter new password" style={{ width: '100%' }} />
                    </div>
                  </div>
                  : null}
              </Stack>
            </Box>
            <Box component="div" textAlign="center" sx={{ p: 2 }}>
              <Button
                /*  onClick={() => {
                   setOpenSetting(false);
                 }} */
                variant="contained"
                sx={{ mr: 2 }}
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  setOpenSetting(false);
                }}
                variant="contained"
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>


      <Modal
        open={openPaidModal1}
        onClose={() => setOpenPaidModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can access only after paid.</p>
          <Stack flexDirection="row">
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
            </Box>

            <Button
              onClick={() => {
                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                setOpenPaidModal(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openActivateConfirmation}
        onClose={() => setActivateConfirmation(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          {/* <TaskAltIcon style={{ color: 'green', fontSize: '40px' }} /> */}
          <p id="child-modal-description" style={{ textAlign: 'center' }}>Activate Plan will immediately cancel your free trial, bill your credit card, and create all your blogs for your plan.  Continue?</p>
          <Stack flexDirection="row">
            {/* <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
            </Box> */}

            <Button
              onClick={handleActivateSubscription}
              variant="contained"
              sx={{ mr: 2 }}
            >
              {isLoading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : 'Yes'}
            </Button>
            <Button
              onClick={() => {
                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                setActivateConfirmation(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              No
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openActivationModal}
        onClose={() => setOpenActivationModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <TaskAltIcon style={{ color: 'green', fontSize: '40px' }} />
          <p id="child-modal-description" style={{ textAlign: 'center' }}>Thank you for your payment.  Your plan is now activated.  We will create, publish, index your blogs daily.  You do not need to do anything.  Our AI Agents will take care of this.  Please see our Info video if you would like to manually edit, change, publish your blogs.</p>
          <Stack flexDirection="row">
            {/* <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
            </Box> */}

            <Button
              onClick={() => {
                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                setOpenActivationModal(false);
                window.location.reload();
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>


      <Modal
        open={openActivateConfirmationModal}
        onClose={() => setOpenActivateConfirmationModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          {/* <TaskAltIcon style={{ color: 'green', fontSize: '40px' }} /> */}
          <p id="child-modal-description" style={{ textAlign: 'center' }}>Activate Plan will immediately start your paid plan and bill your credit card.<br></br>CONTINUE ?</p>
          <Stack flexDirection="row">
            {/* <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
            </Box> */}

            <Button
              onClick={handleActivateSubscription}
              variant="contained"
              sx={{ mr: 2 }}
            >
              {isLoading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : "Yes"}
            </Button>

            <Button
              onClick={() => {
                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                setOpenActivateConfirmationModal(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              No
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={helperPopup}
        /* onClose={(event, reason) => {
            if (reason !== "backdropClick") {
                setHelperModal(false);
            }
        }} */
        disableEscapeKeyDown
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : "70%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: '15px',
            padding: '3%'
          }}
        >
          {/* <video
                        controls
                        style={{ width: '100%' }}
                        src={helpervideosrc}
                        autoPlay
                    /> */}
          <iframe
            width="100%"
            height="500"
            src={helpervideosrc}
            title="Help Video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
          <Stack flexDirection="row">
            <Button
              onClick={() => setHelperPopup(false)}
              variant="contained"
              sx={{ mr: 2, marginTop: '15%' }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
      {/*  */}

      <Modal
        open={upgradeModal}
        /* onClose={(event, reason) => {
            if (reason !== "backdropClick") {
                setHelperModal(false);
            }
        }} */
        disableEscapeKeyDown
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : "40%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: '15px',
            padding: '3%'
          }}
        >
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}><InfoIcon sx={{ color: '#ffcc00' }} /><br />Blog count exceeds your plan limit. Please upgrade your plan to continue.</Typography>

          <Stack flexDirection="row" sx={{ display: 'flex', gap: '10px', marginTop: '1%' }}>

            <Button
              variant="contained"
              onClick={() => {
                const value = {
                  _id: "",
                  product_name: "CodeGenie",
                  product_module: genieSelector.module ? genieSelector.module : "",
                  userid: "",
                  productid: ""
                };
                getProduct(value);
                localStorage.setItem('fromUpgrade', 'true');
                navigate('/pricing', { state: { campIdFromUpgrade: '', from_page: 'blog_listing' } });
              }}>
              Upgrade
            </Button>

            <Button
              onClick={() => setUpgradeModal(false)}
              variant="contained"
            // sx={{ mr: 2, marginTop: '15%' }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
export default Header;
